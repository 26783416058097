.collage {
  position: relative;
  padding-top: 40%;
}

.collage__pattern {
  position: absolute;

  .pattern {
    width: 100%;
    height: 100%;
  }

  &--1 {
    top: 14%;
    right: 53%;
    bottom: 30px;
    left: 9%;
  }

  &--2 {
    top: 14%;
    right: 0;
    bottom: 68px;
    width: 17%;
    opacity: 0.66;
  }
}

.collage__image {
  position: absolute;
  padding: $border-width-thick;
  background-color: $hsd-color-base-white;

  img {
    width: 100%;
    height: auto;
  }

  &--1 {
    top: 14%;
    left: 0;
    width: 40%;
  }

  &--2 {
    bottom: 0;
    left: 50%;
    width: 40%;
  }

  &--3 {
    bottom: 50%;
    left: 34%;
    width: 26%;
  }
}
