.reasons {
  padding: 0;
  list-style: none;
  counter-reset: reason-counter;
}

.reasons__item {
  position: relative;
  counter-increment: reason-counter;

  &:not(:last-child) {
    margin-bottom: spacer(md);
    @include media-breakpoint-up(md) {
      margin-bottom: spacer(lg);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: spacer(xl);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: spacer(xxl);
    }
  }

  @include media-breakpoint-up(lg) {
    .collapse {
      display: block;
    }
  }
}

.reasons__header {
  padding-bottom: spacer(md);
}

.reasons__title {
  @extend .heading--4;
  margin-bottom: spacer(md);
}

.reasons__button {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
