.header {
  // @include make-container();
  @extend .container-padded;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
  }
}

.header__branding,
.header__search {
  padding-top: spacer(md);
  padding-bottom: spacer(sm);
  @include media-breakpoint-up(lg) {
    padding-top: spacer(lg);
    padding-bottom: spacer(md);
  }
  @include media-breakpoint-up(xl) {
    padding-top: spacer(xl);
  }
}

.header__branding {
  margin-right: map_get($grid-column-gutter, "sm");
  @include media-breakpoint-up(md) {
    flex: 1 0 auto;
    margin-right: map_get($grid-column-gutter, "md");
  }
}

.header__search {
  width: 100%;
  @include media-breakpoint-down(md) {
    order: 2;
  }
  @include media-breakpoint-up(md) {
    display: flex;
    flex: 1 0 260px;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
