.article + .article {
  margin-top: spacer(md);
}

.article__header {
  display: flex;
  flex-direction: column;
  max-width: container-max-width(md);
  margin: 0 auto;
  margin-bottom: $spacer;
}

.article__title {
  order: -1;
}

.article__tags {
  order: -2;
  padding: 0;
  font-size: $font-size-small;

  li {
    position: relative;
    display: inline-block;

    &:not(:last-of-type) {
      padding-right: spacer(sm);

      &::before {
        position: absolute;
        right: 0;
        display: block;
        content: "•";
      }
    }
  }
}

.article__meta {
  font-size: $font-size-small;
}
