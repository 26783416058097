.pattern {
  background-size: 72px;

  &.pattern--white {
    @include color-svg("white");
  }

  &.pattern--red {
    @include color-svg("red");
  }

  &.pattern--green {
    @include color-svg("green");
  }

  &.pattern--blue {
    @include color-svg("blue");
  }

  &.pattern--yellow {
    @include color-svg("yellow");
  }

  &.pattern--1 {
    background-image: url("../images/pattern_1.svg");
  }

  &.pattern--2 {
    background-image: url("../images/pattern_2.svg");
  }

  &.pattern--3 {
    background-image: url("../images/pattern_3.svg");
  }

  &.pattern--4 {
    background-image: url("../images/pattern_4.svg");
  }

  &.pattern--5 {
    background-image: url("../images/pattern_5.svg");
  }

  &.pattern--6 {
    background-image: url("../images/pattern_6.svg");
  }

  &.pattern--7 {
    background-image: url("../images/pattern_7.svg");
  }
}
