
.image-link {
  position: relative;
  display: block;

  @include hover-focus {
    .image-link__overlay {
      background-color: rgba($hsd-color-green, 1);
    }
  }
}

.image-link__overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacer(sm);
  background-color: rgba($hsd-color-green, 0.8);
  text-align: center;
  @include transition();
  @include media-breakpoint-up(md) {
    padding: spacer(md);
  }
}
