$embed-responsive-aspect-ratios: () !default;
$embed-responsive-aspect-ratios: join(
  (
    (21 9),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);

.frame {
  position: relative;
  width: 100%;
  padding-bottom: calc(9 / 16 * 100%);
}

.frame > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.frame img,
.frame video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@each $embed-responsive-aspect-ratio in $embed-responsive-aspect-ratios {
  $embed-responsive-aspect-ratio-x: nth($embed-responsive-aspect-ratio, 1);
  $embed-responsive-aspect-ratio-y: nth($embed-responsive-aspect-ratio, 2);

  .frame--#{$embed-responsive-aspect-ratio-x}by#{$embed-responsive-aspect-ratio-y} {
    padding-bottom: percentage($embed-responsive-aspect-ratio-y / $embed-responsive-aspect-ratio-x);
  }
}

.frame--responsive {
  padding-bottom: percentage(3 / 4);
  @include media-breakpoint-down(xs) {
    padding-bottom: percentage(9 / 21);
  }
}

.media-element {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  background-position: 50%;
  background-size: cover;
  text-align: left;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .media-element__item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@each $embed-responsive-aspect-ratio in $embed-responsive-aspect-ratios {
  $embed-responsive-aspect-ratio-x: nth($embed-responsive-aspect-ratio, 1);
  $embed-responsive-aspect-ratio-y: nth($embed-responsive-aspect-ratio, 2);

  .media-element--#{$embed-responsive-aspect-ratio-x}by#{$embed-responsive-aspect-ratio-y} {
    &::before {
      padding-top: percentage($embed-responsive-aspect-ratio-y / $embed-responsive-aspect-ratio-x);
    }
  }
}

.media-element--responsive {
  &::before {
    padding-top: percentage(3 / 4);
    @include media-breakpoint-down(xs) {
      padding-top: percentage(9 / 21);
    }
  }
}
