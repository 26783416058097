.stack > * + * {
  margin-top: spacer(md);
  @include media-breakpoint-up(lg) {
    margin-top: spacer(lg);
  }
}

// Small List
.stack--sm > * + * {
  margin-top: spacer(lg);
}

.stack--md > * + * {
  margin-top: spacer(md);
}

.stack--lg > * + * {
  margin-top: spacer(lg);
}

.stack--xl > * + * {
  margin-top: spacer(xl);
}
