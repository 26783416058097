.page-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: $pullout-width;
  min-height: 200px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header__background {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
  min-height: 360px;
  margin-right: -50vw;
  margin-left: -50vw;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    min-height: 420px;
  }

  picture {
    max-width: $pullout-width;
    margin: 0 auto;
  }

  img {
    height: 100%;
    object-fit: fill;
  }
}

.page-header__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: spacer(md);
  padding-bottom: spacer(xxl);
}

.page-header__portal {
  display: flex;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.page-header__upwards,
.page-header__title a {
  text-decoration: none;
  @include hover-focus {
    color: $hsd-color-text-inverse;
  }
}

.page-header__upwards {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: spacer(sm);
  transition: $transition-base;
  background-color: hsd-darker($hsd-color-red);
  color: $hsd-color-text-inverse;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    max-width: 2.5rem;
  }

  .theme-a &,
  .theme-d & {
    background-color: hsd-darker($hsd-color-yellow);
  }

  .theme-ei &,
  .theme-mv &,
  .theme-m & {
    background-color: hsd-darker($hsd-color-blue);
  }

  .theme-sk &,
  .theme-w & {
    background-color: hsd-darker($hsd-color-green);
  }

  .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  span {
    padding: 0 spacer(sm);
    white-space: nowrap;
  }
}

.page-header__title {
  display: inline-flex;
  align-items: center;

  a {
    padding: spacer(sm) spacer(md);
    background-color: $hsd-color-red;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    color: $hsd-color-text-inverse;

    .theme-a &,
    .theme-d & {
      background-color: $hsd-color-yellow;
      color: $hsd-color-text;
    }

    .theme-ei &,
    .theme-mv &,
    .theme-m & {
      background-color: $hsd-color-blue;
    }

    .theme-sk &,
    .theme-w & {
      background-color: $hsd-color-green;
    }
  }
}

.page-header__subhead {
  @extend .h2;
  line-height: 1.5;
  @include media-breakpoint-down(md) {
    margin-top: spacer(sm);
  }

  span {
    background-color: $hsd-color-base-white;
    @supports (box-decoration-break: clone) {
      padding: spacer(sm) spacer(md);
      box-decoration-break: clone;
    }
  }
}


.page-header__image {
  position: absolute;
  top: 0;
  right: 0;
  flex: 0 0 300px;
  width: 100%;
  max-width: 300px;
  min-height: 200px;
  margin-left: 32.452px;
  background-color: $hsd-color-red;
  z-index: -1;

  .theme-a &,
  .theme-d & {
    background-color: $hsd-color-yellow;
  }

  .theme-ei &,
  .theme-mv &,
  .theme-m & {
    background-color: $hsd-color-blue;
  }

  .theme-sk &,
  .theme-w & {
    background-color: $hsd-color-green;
  }

  &::after {
    position: absolute;
    top: 0;
    left: -32.452px;
    display: block;
    width: 300px;
    height: 200px;
    background-image: url("../images/clip_hsd.svg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 300px 200px;
    content: "";

    .theme-a & {
      background-image: url("../images/clip_1.svg");
    }

    .theme-d & {
      background-image: url("../images/clip_2.svg");
    }

    .theme-ei & {
      background-image: url("../images/clip_3.svg");
    }

    .theme-mv & {
      background-image: url("../images/clip_4.svg");
    }

    .theme-m & {
      background-image: url("../images/clip_5.svg");
    }

    .theme-sk & {
      background-image: url("../images/clip_6.svg");
    }

    .theme-w & {
      background-image: url("../images/clip_7.svg");
    }
  }

  img {
    width: 100%;
    height: auto;
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 33% 100%);
    object-fit: cover;
  }
}

.page-header--lg {
  min-height: 400px;
  margin-top: spacer(md);
  box-shadow: none;
  @include media-breakpoint-up(lg) {
    min-height: 440px;
  }

  .page-header__content {
    position: absolute;
    bottom: 0;
    padding-bottom: spacer(lg);
    @include media-breakpoint-up(md) {
      margin-top: spacer(xxl);
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: spacer(xl);
    }
  }
}
