.semesters .semester:nth-child(even) {
  background-color: $hsd-color-gray-10;
}

.semester {
  @include media-breakpoint-up(lg) {
    display: flex;
    border-top: $border-width-medium solid $hsd-color-border;
  }
}

.semester__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: spacer(xs);
  @include media-breakpoint-down(md) {
    border-bottom: $border-width-medium solid $hsd-color-border;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(1, 5);
    padding: spacer(sm) spacer(md) spacer(sm);
  }
}

.semester__title {
  display: inline-block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.semester__toggle {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  appearance: none;
  border: 0;
  background-color: transparent;
  font-weight: inherit;
  text-align: initial;
  @include media-breakpoint-up(lg) {
    display: none;
  }

  &[aria-expanded="true"] {
    &::before {
      transform: rotate(0deg);
    }
  }

  &::before {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: spacer(sm);
    transform: rotate(-90deg);
    background: transparent url("../icons/i-expand_more.svg") top left no-repeat;
    content: "";
  }
}


.semester__info {
  display: inline-block;
  margin-top: -1 * $border-width-medium;
  padding: 0;
  border: $border-width-medium solid $hsd-color-border;

  dt,
  dd {
    display: inline-block;
    margin: 0;
    padding-top: spacer(xs);
    padding-bottom: spacer(xs);
  }

  dt {
    padding-right: spacer(xs);
    font-weight: inherit;
  }

  dd {
    padding-left: spacer(xs);
  }

  dt + dd {
    border-left-width: inherit;
    border-left-style: solid;
    border-left-color: $hsd-color-border;
  }
}

.semester__body {
  width: 100%;
  padding: spacer(sm) 0;
  @include media-breakpoint-up(lg) {
    box-shadow: inset $border-width-medium 0 0 $hsd-color-border-light;
  }

  &.collapse {
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}


.module {
  & + .module {
    margin-top: spacer(sm);
  }
}

.module__header {
  position: relative;
  padding: spacer(xs);
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    padding: spacer(xs) spacer(md);
  }

  a,
  button {
    text-decoration: underline;
  }

  button {
    padding: 0;
    appearance: none;
    border: 0;
    background-color: transparent;
    font-weight: inherit;
    text-align: initial;
  }
}

.module__body {
  padding: 0 spacer(xs);
  @include media-breakpoint-up(lg) {
    padding: 0 spacer(md);
  }
}

.module__info {
  @include media-breakpoint-up(lg) {
    display: flex;
    margin-left: spacer(lg);

    span {
      min-width: 4.5rem;
      text-align: right;
    }
  }
}

