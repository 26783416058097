.searchbar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.searchbar__input {
  flex: 1;
  width: 260px;
  padding: spacer(xs) 2rem spacer(xs) spacer(sm);
  transition: $transition-base;
  border: 0;
  border: $border-width-medium solid $hsd-color-border;
  background-color: transparent;
  line-height: 1.25;

  .searchbar:focus-within &,
  &:focus {
    border-color: $hsd-color-link-focus;
    outline: none;
  }
}

.searchbar__options {
  position: absolute;
  top: calc(100% - #{$border-width-medium});
  left: 0;
  display: none;
  width: 260px;
  padding: spacer(xs) spacer(sm);
  border: $border-width-medium solid  $hsd-color-link-focus;
  background-color: $hsd-color-base-white;

  .searchbar__input:focus + &,
  .searchbar.focus & {
    display: block;
  }

  .searchbar:focus-within & {
    display: block;
  }
}

.searchbar__button {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  padding: 0.125rem;
  border: $border-width-medium solid $hsd-color-border;

  .searchbar.focus & {
    border-color: $hsd-color-link-focus;
  }

  .searchbar:focus-within & {
    border-color: $hsd-color-link-focus;
  }

  @include hover-focus {
    border-color: $hsd-color-link-focus;
    outline: none;
    background-color: $hsd-color-link-focus;
    color: $hsd-color-text-inverse;
  }
}
