.branding {
  display: flex;
  flex-flow: row nowrap;
  margin-left: -5px;
  overflow: hidden;

  @include media-breakpoint-down(xs) {
    .logo:not(:last-child) {
      display: none;
    }
  }
}

.logo {
  &:not(:last-child) {
    margin-right: 16px;
    @include media-breakpoint-up(sm) {
      margin-right: 46px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 65px;
    }
  }

  a {
    display: block;
  }

  img {
    height: 120px;
  }
}

.logo-initial {
  height: 81px;
  margin-bottom: 20px;
}
