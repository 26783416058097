.page {
  @extend .container-padded;
}

.page__header {
  width: 100%;
  padding-top: spacer(lg);
  hyphens: auto;
  @include media-breakpoint-up(lg) {
    padding-top: spacer(xl);
    padding-bottom: spacer(lg);
  }
  @include media-breakpoint-up(xl) {
    padding-top: spacer(xxl);
    padding-bottom: spacer(xl);
  }
}


.page--sidebar {
  .page__main {
    @include media-breakpoint-up(lg) {
      @include make-row();
    }
  }

  .page__side,
  .page__content {
    @include media-breakpoint-up(lg) {
      @include make-col-ready();
    }
  }

  .page__side {
    @include media-breakpoint-up(lg) {
      @include make-col(3);
      @include make-col-offset(1);
    }
  }

  .page__content {
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }
}
