
.cover {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: none;
  transform: translate(-50%, -50%);

  @supports (object-fit: cover) {
    object-fit: cover;
  }
}

iframe.cover {
  pointer-events: none;
}

.cover__container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
