.info-box {
  @extend .pull--x;
  @extend .pad--around;
  background-color: $hsd-color-gray-10;
}

.info-box__links {
  @include list-unstyled;
  @each $breakpoint, $value in $grid-column-gutter {
    @include media-breakpoint-up($breakpoint) {
      grid-column-gap: $value;
    }
  }
  @include media-breakpoint-up(md) {
    columns: 2;
  }
  @include media-breakpoint-up(lg) {
    columns: 3;
  }
}
