$hamburger-layer-height: $border-width-medium !default;
$hamburger-layer-spacing: $border-width-thick !default;

.hamburger {
  display: inline-flex;
  align-items: center;
  @include button-size($btn-padding-y, $btn-padding-y, $btn-font-size, $btn-line-height, $btn-border-radius);
  padding-right: $btn-padding-x;
  border: 0;
  border: $border-width-medium solid $hsd-color-border;
  background-color: $hsd-color-base-white;
  font-weight: $btn-font-weight;
  text-align: center;
  text-decoration: none;
  color: $body-color;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  @include transition($btn-transition);

  @include hover-focus {
    border-color: $hsd-color-link-hover;

    .hamburger__inner,
    .hamburger__inner::before,
    .hamburger__inner::after {
      background-color: $hsd-color-link-hover;
    }
  }

  @include hover {
    text-decoration: none;
    color: $hsd-color-link-hover;
  }

  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &:active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &[aria-expanded=true] {
    .hamburger__inner {
      transform: rotate(45deg);
      transition-delay: $duration-promptly;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        transition: top $duration-quickly ease,
          opacity $duration-quickly $duration-promptly ease;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom $duration-quickly ease,
          transform $duration-quickly $duration-promptly cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.hamburger__box {
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  margin-right: spacer(sm);
}

.hamburger__inner {
  top: 50%;
  display: block;
  margin-top: $hamburger-layer-height / -2;
  transition-duration: $duration-quickly;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &,
  &::before,
  &::after {
    position: absolute;
    width: 1.5rem;
    height: $hamburger-layer-height;
    transition-duration: $duration-promptly;
    transition-property: transform;
    transition-timing-function: ease;
    background-color: $hsd-color-link;
  }

  &::before,
  &::after {
    display: block;
    content: "";
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    transition: top $duration-quickly $duration-promptly ease,
      opacity $duration-quickly ease;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    transition: bottom $duration-quickly $duration-promptly ease,
      transform $duration-quickly cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}
