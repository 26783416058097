.block {
  position: relative;

  & + .block {
    margin-top: spacer(md);
    @include media-breakpoint-up(lg) {
      margin-top: spacer(lg);
    }
    @include media-breakpoint-up(xl) {
      margin-top: spacer(xl);
    }
  }
}

.block--md {
  max-width: percentage(10 / $grid-columns);
  @include make-col-offset(1);
}

.block--sm {
  max-width: percentage(8 / $grid-columns);
  @include make-col-offset(2);
}
