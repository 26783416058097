
// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

.flex {
  display: flex;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{if($infix == "", "-", "-#{$infix}")}-row {
      flex-direction: row !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-column {
      flex-direction: column !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-column-reverse {
      flex-direction: column-reverse !important;
    }


    .flex#{if($infix == "", "-", "-#{$infix}")}-wrap {
      flex-wrap: wrap !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }


    .flex#{if($infix == "", "-", "-#{$infix}")}-fill {
      flex: 1 1 auto !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-grow-0 {
      flex-grow: 0 !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-grow-1 {
      flex-grow: 1 !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex#{if($infix == "", "-", "-#{$infix}")}-shrink-1 {
      flex-shrink: 1 !important;
    }


    .justify-content#{if($infix == "", "-", "-#{$infix}")}-start {
      justify-content: flex-start !important;
    }

    .justify-content#{if($infix == "", "-", "-#{$infix}")}-end {
      justify-content: flex-end !important;
    }

    .justify-content#{if($infix == "", "-", "-#{$infix}")}-center {
      justify-content: center !important;
    }

    .justify-content#{if($infix == "", "-", "-#{$infix}")}-between {
      justify-content: space-between !important;
    }

    .justify-content#{if($infix == "", "-", "-#{$infix}")}-around {
      justify-content: space-around !important;
    }


    .align-items#{if($infix == "", "-", "-#{$infix}")}-start {
      align-items: flex-start !important;
    }

    .align-items#{if($infix == "", "-", "-#{$infix}")}-end {
      align-items: flex-end !important;
    }

    .align-items#{if($infix == "", "-", "-#{$infix}")}-center {
      align-items: center !important;
    }

    .align-items#{if($infix == "", "-", "-#{$infix}")}-baseline {
      align-items: baseline !important;
    }

    .align-items#{if($infix == "", "-", "-#{$infix}")}-stretch {
      align-items: stretch !important;
    }


    .align-content#{if($infix == "", "-", "-#{$infix}")}-start {
      align-content: flex-start !important;
    }

    .align-content#{if($infix == "", "-", "-#{$infix}")}-end {
      align-content: flex-end !important;
    }

    .align-content#{if($infix == "", "-", "-#{$infix}")}-center {
      align-content: center !important;
    }

    .align-content#{if($infix == "", "-", "-#{$infix}")}-between {
      align-content: space-between !important;
    }

    .align-content#{if($infix == "", "-", "-#{$infix}")}-around {
      align-content: space-around !important;
    }

    .align-content#{if($infix == "", "-", "-#{$infix}")}-stretch {
      align-content: stretch !important;
    }


    .align-self#{if($infix == "", "-", "-#{$infix}")}-auto {
      align-self: auto !important;
    }

    .align-self#{if($infix == "", "-", "-#{$infix}")}-start {
      align-self: flex-start !important;
    }

    .align-self#{if($infix == "", "-", "-#{$infix}")}-end {
      align-self: flex-end !important;
    }

    .align-self#{if($infix == "", "-", "-#{$infix}")}-center {
      align-self: center !important;
    }

    .align-self#{if($infix == "", "-", "-#{$infix}")}-baseline {
      align-self: baseline !important;
    }

    .align-self#{if($infix == "", "-", "-#{$infix}")}-stretch {
      align-self: stretch !important;
    }
  }
}
