.features-visualized__item {
  @include make-row();
  @include media-breakpoint-up(md) {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  @include media-breakpoint-up(lg) {
    align-items: center;
  }

  & + .features-visualized__item {
    margin-top: spacer(xl);
    @include media-breakpoint-up(md) {
      margin-top: spacer(xl);
    }
    @include media-breakpoint-up(xl) {
      margin-top: spacer(xxl);
    }
  }
}

.features-visualized__text,
.features-visualized__image {
  @include make-col-ready();
}

.features-visualized__image {
  @include media-breakpoint-down(sm) {
    max-width: $size-7;
  }
  @include media-breakpoint-up(md) {
    @include make-col(6, $grid-columns);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(7, $grid-columns);
  }
}

.features-visualized__text {
  @include media-breakpoint-down(sm) {
    margin-top: spacer(md);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6, $grid-columns);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4, $grid-columns);
  }
}
