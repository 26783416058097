// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
//    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 6. Change the default tap highlight to be completely transparent in iOS.


/// Resets default browser styling
/// @access public
/// @group global-reset
@mixin reset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;

  *,
  *::before,
  *::after {
    box-sizing: inherit;  // 1
  }
}


@if $css--global-reset == true {
  html {
    @include reset;
    font-family: sans-serif; // 2
    line-height: 1.15; // 3
    -webkit-text-size-adjust: 100%; // 4
    -ms-text-size-adjust: 100%; // 4
    -ms-overflow-style: scrollbar; // 5
    -webkit-tap-highlight-color: rgba($hsd-color-base-black, 0); // 6
    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }
} @else {
  .css-boundary {
    @include reset;
  }
}
