.card-slide {
  margin-right: -1 * map_get($container-gutters, "sm");
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none; // Firefox
  -ms-overflow-style: none; // Internet Explorer, Edge
  -webkit-overflow-scrolling: touch; // Webkit
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  @include media-breakpoint-up(md) {
    margin-right: -1 * map_get($container-gutters, "md");
  }
  @include media-breakpoint-up(lg) {
    margin-right: -1 * map_get($container-gutters, "lg");
  }
  @include media-breakpoint-up(xl) {
    margin-right: -0.5 * map_get($grid-column-gutter, "xl");
    margin-left: -0.5 * map_get($grid-column-gutter, "xl");
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.card-slide__track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;

  // Force same height
  .card {
    height: 100%;
  }
}


.card-slide__item {
  flex: 1 0 auto;
  scroll-snap-align: start;
  width: 90%;
  max-width: 400px;
  padding-right: map_get($container-gutters, "sm");
  @include media-breakpoint-up(md) {
    width: 60%;
    padding-right: map_get($container-gutters, "md");
  }
  @include media-breakpoint-up(lg) {
    width: 45%;
    padding-right: map_get($container-gutters, "lg");
  }
  @include media-breakpoint-up(xl) {
    width: percentage(4 / 12);
    max-width: percentage(4 / 12);
    padding-right: map_get($grid-column-gutter, "xl") / 2;
    padding-left: map_get($grid-column-gutter, "xl") / 2;
  }
}

// .service__card {
//   position: relative;
//   height: 100%;
//   padding: 1rem 1rem 1.5rem;
//   border-top: 3px solid $black;
//   border-radius: $border-radius;
//   background-color: $park-white;
//   @include media-breakpoint-down(sm) {
//     @include make-col-ready();
//   }
//   @include media-breakpoint-up(xl) {
//     display: flex;
//   }

//   @each $theme-name, $theme-color in $park-themes {
//     .theme-#{$theme-name} & {
//       border-color: color-lighter($theme-color);
//     }
//   }

//   .icon {
//     width: 3.5rem;
//     height: 3.5rem;
//     padding: 0.875rem;
//   }

//   h3 {
//     @include media-breakpoint-up(lg) {
//       margin-top: 0.25rem;
//     }
//   }

//   a {
//     text-decoration: none;

//     &:focus,
//     &:active {
//       text-decoration: none;
//     }

//     &::after {
//       position: absolute;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;
//       content: "";
//     }
//   }
// }

// .service__card--link {
//   &:hover {
//     background-color: $gray-100;

//     .service__forward {
//       opacity: 1;
//     }
//   }

//   &:focus-within {
//     background-color: $gray-100;
//   }
// }

// .service__text {
//   flex: 1;
//   min-width: 0;
//   @include media-breakpoint-down(lg) {
//     margin-top: 0.5rem;
//   }
//   @include media-breakpoint-up(xl) {
//     margin-left: 1rem;
//   }
// }

// .service__forward {
//   position: absolute;
//   right: 1rem;
//   bottom: 0;
//   opacity: 0.5;

//   .icon {
//     width: 2rem;
//     height: 2rem;
//     padding: 0;
//     background-color: transparent !important;
//     fill: $black;
//     @each $theme-name, $theme-color in $park-themes {
//       .theme-#{$theme-name} & {
//         fill: $theme-color;
//       }
//     }
//   }
// }
