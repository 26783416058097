.toc-box {
  position: relative;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    &::before {
      display: block;
      width: 1px;
      height: 0;
      padding-bottom: 100%;
      content: "";
    }
  }

  &:hover .toc-box__image::before {
    opacity: 0.9;
  }
}

.toc-box__content {
  padding: spacer(md) spacer(sm);
  z-index: 1;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.toc-box__image {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: $hsd-color-base-black;
    content: "";
    opacity: 0.8;
    @include transition();

    .theme-a &,
    .theme-d & {
      background-color: $hsd-color-yellow;
    }

    .theme-ei &,
    .theme-mv &,
    .theme-m & {
      background-color: $hsd-color-blue;
    }

    .theme-sk &,
    .theme-w & {
      background-color: $hsd-color-green;
    }
  }

  img,
  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
