.skip em {
  @include sr-only();
}

.skip__nav {
  margin: 0;

  li {
    list-style: none;
  }

  a {
    display: block;
    @include sr-only();
    @include focus-active {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      padding: spacer(xs) spacer(sm);
      background-color: $hsd-color-base-white;
      font-size: $font-size-small;
      text-align: center;
      white-space: normal;
      overflow: visible;
      z-index: 1000;
      clip: auto;
      @include media-breakpoint-up(lg) {
        padding: spacer(sm);
      }
    }
  }
}
