.float-bar {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  list-style-type: none;
  height: auto !important;
  z-index: 8000;
  @include transition(transform $duration-slowly ease);
  @include media-breakpoint-down(sm) {
    top: 0;
    right: 0;
    justify-content: center;
  }
  @include media-breakpoint-up(md) {
    top: 280px;
    right: 0;
    flex-direction: column;
  }
  @include media-breakpoint-up(lg) {
    right: 1rem;
  }

  // Hide if no JS ist enabled
  .no-js & {
    display: none;
  }

  button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 2rem;
    height: 2rem;
    padding: 0;
    background-color: $hsd-color-red;
    color: $hsd-color-text-inverse;
    @include media-breakpoint-up(md) {
      width: 2.5rem;
      height: 2.5rem;
    }

    @include hover-focus {
      color: $hsd-color-text-inverse;
      overflow: visible;

      span {
        opacity: 1;
        @include media-breakpoint-down(sm) {
          top: 45px;
        }
        @include media-breakpoint-up(md) {
          top: 0.125rem;
          right: 65px;
        }
      }
    }
  }

  svg.icon {
    position: absolute;
    top: 2px;
    left: 2px;
    margin-right: 0;
    @include media-breakpoint-up(md) {
      top: 6px;
      left: 6px;
    }
  }

  span {
    position: absolute;
    display: block;
    width: auto;
    padding: spacer(xs) spacer(sm);
    background: $hsd-color-red;
    color: $hsd-color-text-inverse;
    white-space: nowrap;
    opacity: 0;
    @include media-breakpoint-down(sm) {
      top: 55px;
      transition: top 0.5s, opacity 0.5s;
    }
    @include media-breakpoint-up(md) {
      top: 0.125rem;
      right: 75px;
      transition: right 0.5s, opacity 0.5s;
    }

    &::before {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      content: "";
      @include media-breakpoint-down(sm) {
        top: -6px;
        right: 6px;
        border-width: 0 5px 6px;
        border-color: transparent transparent $hsd-color-red transparent;
      }
      @include media-breakpoint-up(md) {
        top: 10px;
        right: -6px;
        border-width: 5px 0 5px 6px;
        border-color: transparent transparent transparent $hsd-color-red;
      }
    }
  }
}

.float-bar__opener:not(.show) {
  display: none;
}

.float-bar__content {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: $hsd-color-base-white;
  overflow-x: scroll;
  z-index: 101;
  @include media-breakpoint-down(sm) {
    width: 90%;
    margin-bottom: spacer(md);
    padding: 60px map_get($container-gutters, "sm");
  }
  @include media-breakpoint-up(md) {
    width: 750px;
    padding: 80px 280px 40px 40px;
  }
}

.float-bar__close {
  position: absolute;
  @include media-breakpoint-down(sm) {
    top: 0;
    left: map_get($container-gutters, "sm");
  }
  @include media-breakpoint-up(md) {
    top: spacer(md);
    right: spacer(md);
  }
}

.float-bar__mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  content: "";
  overflow: hidden;
  z-index: 100;
}
