//
// Subnav
//

.subnav {
  position: relative;
  margin-top: spacer(sm);
  z-index: 1;
  @include media-breakpoint-up(md) {
    margin-top: spacer(md);
  }

  .page-header:not(.page-header--lg) + & {
    margin-top: -40px;
    @include media-breakpoint-up(md) {
      margin-top: -2.25rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -2.75rem;
    }
  }
}

.subnav__toggle {
  margin-bottom: spacer(sm);
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.subnav__collapse {
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.subnav__items {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    border: 0;
  }
}

.subnav__item {
  position: relative;
  display: flex;
  flex: none;
  flex-flow: row wrap;
  justify-content: flex-start;
  border: $border-width-medium solid $hsd-color-text-secondary;
  background-color: $hsd-color-base-white;

  &:not(:first-child) {
    @include media-breakpoint-down(sm) {
      margin-top: -1 * $border-width-medium;
    }
    @include media-breakpoint-up(md) {
      margin-left: -1 * $border-width-medium;
    }
  }

  &:focus,
  &:hover {
    color: $hsd-color-ink;

    @include media-breakpoint-up(md) {
      & > .icon {
        transform: rotate(180deg);
      }
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    appearance: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      &[aria-expanded="true"] + .icon {
        transform: rotate(180deg);
      }
    }
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  & > .icon {
    position: absolute;
    top: 0.25rem;
    right: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    @include media-breakpoint-up(lg) {
      top: spacer(sm);
      right: spacer(sm);
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      ul {
        display: block;
      }
    }
  }

  &.has-children > a {
    padding-right: 1.5rem;
    @include media-breakpoint-up(lg) {
      padding-right: 2.5rem;
    }
  }
}

.subnav__link {
  position: relative;
  display: inline-block;
  flex: 1 0 auto;
  padding: spacer(sm) 0 spacer(sm) spacer(md);
  font-weight: $font-weight-bold;
  line-height: 1;
  text-decoration: none;
  color: $hsd-color-text-secondary;
  @include media-breakpoint-up(md) {
    display: block;
    padding: spacer(sm);

    &:hover,
    .subnav__item:focus-within & {
      color: $hsd-color-link;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 11px spacer(md);
  }

  @include hover-focus {
    background-color: $hsd-color-link-hover-bg;
    text-decoration: none;
    color: $hsd-color-link;
  }

  .subnav__item.active > &,
  &:active {
    color: $hsd-color-ink;
  }
}

// Second level
.subnav__item ul {
  min-width: 100%;
  background-color: $hsd-color-base-white;
  list-style: none;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 100%;
    left: -1 * $border-width-medium;
    display: none;
    min-width: 240px;
    border: $border-width-medium solid $hsd-color-text-secondary;

    .subnav__item:hover & {
      display: block;
    }
  }

  .subnav__link {
    font-weight: $font-weight-base;

    &::after {
      background-color: $hsd-color-gray-50;
    }
  }
}
