//
// Figures
//

.figure {
  // Ensures the caption's text aligns with the image.
  display: inline-block;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: $spacer;
  }
}

.figure__container {
  position: relative;
}

.figure__copyright {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: spacer(sm) spacer(md);
  font-size: $font-size-small;
  color: $hsd-color-text-secondary;
  opacity: 0.6;

  .figure:hover & {
    opacity: 1;
  }
}

.figure__caption {
  max-width: container-max-width(md);
  margin-right: auto;
  margin-left: auto;
  padding: spacer(md);
  background-color: $hsd-color-gray-30;
  font-size: $font-size-small;
}
