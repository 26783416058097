.slider {
  position: relative;
}

.slider__gallery {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-snap-points-x: repeat(100%);
  scroll-behavior: smooth;

  &:focus {
    outline: none;
  }
}

// REVIEW: Focus state
// https://css-tricks.com/keyboard-only-focus-styles/
// .slider:focus,
// [aria-label="gallery controls"] button:focus,
// .slider a:focus img {
//   outline: 4px solid DodgerBlue;
//   outline-offset: -6px;
// }
//
// [aria-label="gallery controls"] button:focus {
//   outline-offset: -4px;
// }

.slider__list {
  display: flex;
}

.slider__item {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  list-style: none;
  scroll-snap-align: start;
}

.slider figure {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.slider a:focus {
  outline: none;
}

.slider figcaption {
  padding: 0.5rem;
}

.slider img {
  min-width: 1px;
  max-width: 100%;
  min-height: 1px;
}

.slider__instructions {
  min-height: 36px;
}

.slider__instructions p {
  text-align: center;
  color: $hsd-color-text-secondary;
}

.slider__instruction {
  position: relative;
  display: none;
}

.slider__gallery:focus + .slider__instructions .slider__instruction--focus,
.slider__gallery:hover + .slider__instructions .slider__instruction--hover {
  display: block;
}

.slider__gallery:hover + .slider__instructions .slider__instruction--hover + .slider__instruction--focus {
  display: none;
}

.slider__gallery:hover:focus + .slider__instructions .slider__instruction--hover,
.slider__gallery:hover:focus + .slider__instructions .slider__instruction--focus {
  display: none;
}

.slider__gallery:hover:focus + .slider__instructions .slider__instruction--hover-and-focus {
  display: block;
}

.touch .slider__instructions p {
  display: none !important;
}

.touch .slider__instructions .slider__instruction--touch {
  display: block !important;
}

.slider__controls {
  // smartphones, touchscreens
  @media (hover: none) and (pointer: coarse) {
    display: none;
  }

  li {
    list-style: none;
  }

  button {
    position: absolute;
    top: calc(50% - 36px);
    width: 2rem;
    height: 36px;
    padding: 0 2px;
    appearance: none;
    border: none;
    border-radius: 0;
    background-color: $hsd-color-base-white;
    opacity: 0.8;
    overflow: hidden;

    .slider:hover & {
      opacity: 1;
    }

    &::before {
      position: absolute;
      top: 50%;
      display: block;
      width: 2rem;
      height: 2rem;
      margin-top: -1rem;
      background-color: $hsd-color-base-black;
      content: "";
    }
  }

  [aria-label="previous"] {
    left: 0;
    @include media-breakpoint-up(md) {
      left: -2rem;
    }

    &::before {
      right: -2rem;
    }
  }

  [aria-label="next"] {
    right: 0;
    @include media-breakpoint-up(md) {
      right: -2rem;
    }

    &::before {
      left: -2rem;
    }
  }

  .icon {
    position: relative;
  }
}

.slider--logos {
  .slider__gallery {
    padding: spacer(md) 0;
    scroll-snap-points-x: repeat(100%);
    @include media-breakpoint-up(md) {
      scroll-snap-points-x: repeat(30%);
    }
    @include media-breakpoint-up(lg) {
      scroll-snap-points-x: repeat(18%);
    }
  }

  .slider__item {
    display: flex;
    flex: 0 0 40%;
    align-items: center;
    width: 40%;
    padding: 0 spacer(md);
    @include media-breakpoint-up(md) {
      flex: 0 0 30%;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 18%;
    }
  }
}

.slider--sm {
  .slider__item {
    flex: 0 0 50%;
    width: 50%;
    padding: 0 spacer(sm);
    @include media-breakpoint-up(md) {
      flex: 0 0 40%;
      width: 40%;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 30%;
      width: 30%;
    }
  }
}

.slider--cards {
  .slider__item {
    flex: 0 0 100%;
    width: 100%;
    padding: spacer(md);
    border: $border-width-medium solid $hsd-color-border-light;
    @include media-breakpoint-up(md) {
      flex: 0 0 75%;
      width: 75%;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 60%;
      width: 60%;
    }

    & + .slider__item {
      margin-left: spacer(md);
    }
  }
}
