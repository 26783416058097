.clue {
  position: relative;
  padding-top: spacer(md);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 6rem;
    height: $border-width-thick;
    background-color: $hsd-color-base-black;
    content: "";
    @include transition(width $duration-slowly ease-in-out);
  }
}

.clue__anchor:hover {
  .clue::before {
    width: 100%;
  }
}
