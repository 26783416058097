//
// Base Lists
//

.list,
.list--unstyled {
  @include list-unstyled;
}

.list.list--unstyled {
  li {
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}

.list li + li {
  margin-top: spacer(xs);

  &::before {
    margin-top: spacer(xs);
  }
}

//
// Inline turns list items into inline-block

.list--inline {
  display: flex;
  flex-flow: row wrap;

  li + li {
    margin-top: 0;
  }

  li {
    display: inline;

    &:not(:first-child) {
      margin-top: 0;
    }

    a {
      display: inline-flex;
    }

    .icon:first-child {
      margin-right: spacer(xs);
    }

    .icon:last-child {
      margin-left: spacer(xs);
    }

    &:not(:last-child) {
      margin-right: spacer(sm);
      @include media-breakpoint-up(md) {
        margin-right: spacer(md);
      }
    }
  }
}

//
// List Style Bordered

.list--bordered {
  li:not(:first-child) {
    border-top: $border-width-thin solid $hsd-color-border-light;
  }
}

//
// List Style Marker
.text ul,
.list--marker {
  @include list-unstyled;
}

.text ul li,
.list--marker li {
  position: relative;
  padding-left: spacer(lg);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding-top: 0;
    font-size: inherit;
    line-height: 1.3;
    content: "–";
  }
}

//
// Arrow list
.list--arrow li {
  display: flex;
  align-items: center;
  padding: spacer(xxs) 0;
  line-height: 1.5rem;
  @include media-breakpoint-up(lg) {
    padding: spacer(sm) 0;
  }

  &::before {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: spacer(sm);
    background: url("../icons/i-arrow_right.svg") top left no-repeat;
    content: "";
  }
}

.list--lower-alpha {
  margin-left: spacer(lg);
  list-style: lower-alpha;
}

//
// Numbered list with display font

.list--numbers {
  counter-reset: big-counter;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    counter-increment: big-counter;

    &,
    &:not(:last-child) {
      padding: spacer(sm) spacer(md);
    }

    &::before {
      display: block;
      min-width: 1em;
      margin-top: 0;
      padding-right: spacer(md);
      font-family: $font-family-display;
      font-size: $display-font-size-md;
      line-height: 1;
      color: $hsd-color-red;
      content: counter(big-counter) ".";
      @include media-breakpoint-up(md) {
        min-width: 2em;
        font-size: $display-md-font-size-md;
      }
      @include media-breakpoint-up(lg) {
        font-size: $display-lg-font-size-md;
      }

      .theme-a &,
      .theme-d & {
        color: $hsd-color-yellow;
      }

      .theme-ei &,
      .theme-mv &,
      .theme-m & {
        color: $hsd-color-blue;
      }

      .theme-sk &,
      .theme-w & {
        color: $hsd-color-green;
      }
    }

    & + li {
      margin-top: spacer(xs);

      @include media-breakpoint-up(lg) {
        margin-top: spacer(sm);
      }
    }
  }
}

//
// Description Lists
//

.dl-group {
  padding: 0;

  dt,
  dd {
    @include media-breakpoint-up(md) {
      padding: spacer(sm) 0;
    }
  }

  dt {
    font-weight: $font-weight-normal;
    @include media-breakpoint-down(md) {
      padding: spacer(sm) 0 0;
    }

  }

  dd {
    margin-bottom: 0;
    color: $hsd-color-text;
    @include media-breakpoint-down(md) {
      padding: 0 0 spacer(sm);
    }
  }
}

.dl-group--horizontal {
  display: flex;
  flex-wrap: wrap;

  dt,
  dd {
    width: 100%;
    vertical-align: top;
  }

  dt {
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
    @include media-breakpoint-up(xl) {
      width: 30%;
    }
  }

  dd {
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
    @include media-breakpoint-up(xl) {
      width: 70%;
    }
  }
}

// Description List style condensed dl-group w/ half padding
.dl-group--sm {
  dt,
  dd {
    @include media-breakpoint-up(md) {
      padding: spacer(xs) 0;
    }
  }

  dt {
    @include media-breakpoint-down(md) {
      padding: spacer(xs) 0 0;
    }
  }

  dd {
    @include media-breakpoint-down(md) {
      padding: 0 0 spacer(xs);
    }
  }
}

// Description List style bordered
.dl-group--bordered {
  dt:not(:first-of-type) {
    border-top: $border-width-thin solid $hsd-color-border-light;
  }

  &.dl-group--horizontal {
    dd:not(:first-of-type) {
      @include media-breakpoint-up(md) {
        border-top: $border-width-thin solid $hsd-color-border-light;
      }
    }
  }
}
