/// Grid system
//
// Generate semantic grid columns with these mixins.

/// Creates responsive gaps for a css grid
///
/// @param {String} $direction - Gab direction
@mixin grid-column-gap-responsive($direction: "both") {
  @if $direction == "both" {
    grid-gap: map_get($grid-column-gutter, "sm");
    @each $breakpoint, $value in $grid-column-gutter {
      @include media-breakpoint-up($breakpoint) {
        grid-row-gap: $value / 2;
        grid-column-gap: $value;
      }
    }
  }

  @if $direction == "column" {
    @each $breakpoint, $value in $grid-column-gutter {
      @include media-breakpoint-up($breakpoint) {
        grid-column-gap: $value;
      }
    }
  }

  @if $direction == "row" {
    @each $breakpoint, $value in $grid-column-gutter {
      @include media-breakpoint-up($breakpoint) {
        grid-row-gap: $value / 2;
      }
    }
  }
}

@mixin make-container() {
  width: 100%;
  // @include make-container-max-widths();
  max-width: map_get($container-max-widths, "xl");
  margin-right: auto;
  margin-left: auto;
  padding-right: map_get($container-gutters, "sm");
  padding-left: map_get($container-gutters, "sm");
}

@mixin make-container-max-widths() {
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-container-padded() {
  @each $breakpoint, $value in $container-gutters {
    @include media-breakpoint-up($breakpoint) {
      padding-right: $value;
      padding-left: $value;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @each $breakpoint, $value in $grid-column-gutter {
    @include media-breakpoint-up($breakpoint) {
      $margin: ($value / -2);
      margin-right: $margin;
      margin-left: $margin;
    }
  }
}

@mixin row-collapse($breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-down($breakpoint) {
      .row#{if($infix == "", "-collapse", "-#{$infix}-collapse")} {
        flex-direction: column;

        > .col,
        > [class*="col-"] {
          flex: 0 0 100%;
          margin-bottom: spacer(lg);
        }
      }
    }
  }
}

@mixin make-grid() {
  display: grid;
  @include grid-column-gap-responsive();

  & > .card + .card {
    margin-top: 0;
  }
}

@mixin col-responsive-padding($gutters: $grid-column-gutter) {
  @each $breakpoint, $value in $grid-column-gutter {
    $padding: ($value / 2);
    @include media-breakpoint-up($breakpoint) {
      padding-right: $padding;
      padding-left: $padding;
    }
  }
}

@mixin make-col-ready() {
  // position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  @include col-responsive-padding();
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}
