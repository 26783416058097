// Cards

//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: $hsd-color-base-white;

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  @include hover-focus {
    color: $hsd-color-text;
  }

  & + .card {
    margin-top: spacer(md);
  }
}

.card__body {
  display: flex;
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  padding-top: spacer(md);
  padding-bottom: spacer(lg);
}

.card__title {
  @extend .heading--5;
  margin-bottom: spacer(sm);
}

.card__subtitle {
  margin-top: -(spacer(sm) / 2);
  margin-bottom: 0;
}

.card__text {
  flex: 1;

  &:not(:last-child) {
    margin-bottom: spacer(md);
  }
}

.card__link {
  @include hover {
    text-decoration: none;
  }

  + .card__link {
    margin-left: spacer(md);
  }
}

.card__more {
  justify-content: space-between;
  padding-right: 0;
  padding-left: 0;
}

//
// Optional textual caps
//

.card__header {
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  padding: spacer(sm) spacer(md);
  background-color: rgba($hsd-color-base-black, 0.03);
  color: $body-color;

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card__footer {
  padding: spacer(sm) spacer(md);
  background-color: rgba($hsd-color-base-black, 0.03);
}

// Card image
.card__media {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  height: auto;
}

.card__media--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.2 5rem;
}

.card--light {
  background-color: $hsd-color-gray-40;
}

.card--primary {
  background-color: $hsd-color-red;

  .card__body {
    padding-right: spacer(md);
    padding-left: spacer(md);
  }
}

//
// Card horizontal

.card--horizontal {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .card__image,
    .card__body {
      width: 100%;
      min-height: 1px;
    }

    .card__image {
      @include make-col(1, 3);
    }

    .card__body {
      @include make-col(2, 3);
      padding-top: 0;
      padding-left: spacer(md);

      &:nth-last-child(1) {
        flex: 1;
      }
    }
  }
}

.card--bordered {
  border: $border-width-medium solid $hsd-color-border;
}

.card--lifted {
  border: $border-width-medium solid $hsd-color-border;
  background-color: $hsd-color-base-white;
  @include box-move();
}

.card--tagged {
  position: relative;

  &::after {
    position: absolute;
    top: -1 * $border-width-medium;
    right: -1 * $border-width-medium;
    display: block;
    width: 0;
    border-top: 3rem solid $hsd-color-border;
    border-left: 3rem solid transparent;
    content: "";
  }
}

//
// Card feature

.card--feature {
  grid-column: 1 / -1;
  @include media-breakpoint-up(md) {
    @include make-row();

    .card__media,
    .card__body {
      @include make-col-ready();
      @include make-col(6, 12);
    }

    .card__body:first-child {
      padding-top: spacer(md);
    }

    .card__text {
      flex: initial;
    }
  }
}

.card--hover {
  .card__title a {
    text-decoration: none;
    box-shadow: none;

    @include hover-focus {
      box-shadow: none;

      &::after {
        width: 100%;
        background-color: $hsd-color-red;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      z-index: 1;
    }

    &::after {
      position: absolute;
      bottom: 0;
      display: block;
      width: 6rem;
      height: $border-width-thick;
      margin-top: auto;
      background-color: $hsd-color-base-black;
      content: "";
      @include transition;
    }
  }
}
