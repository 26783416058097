

.profile {
  margin-top: spacer(xxl);
  margin-bottom: $spacer;
  @include media-breakpoint-up(md) {
    margin-top: spacer(lg);
  }
}

.profile__teaser {
  width: 100%;
  padding: spacer(md) 0;
  background-color: $hsd-color-gray-20;
  @include media-breakpoint-up(md) {
    display: flex;
    flex-flow: row nowrap;
    padding: spacer(lg) 0;
  }
}

.profile__image {
  margin-top: -1 * spacer(xxl);
  padding-left: map_get($grid-column-gutter, "sm");
  @include media-breakpoint-down(md) {
    margin-bottom: spacer(sm);
  }
  @include media-breakpoint-up(md) {
    flex: 0 0 percentage(3 / 12);
    max-width: percentage(3 / 12);
    margin-top: -1 * spacer(xl);
    padding-right: map_get($grid-column-gutter, "sm") / 2;
  }
  @include media-breakpoint-up(lg) {
    flex: 0 0 percentage(2 / 12);
    max-width: percentage(2 / 12);
    padding-right: map_get($grid-column-gutter, "lg") / 2;
  }
  @include media-breakpoint-up(xl) {
    padding-right: map_get($grid-column-gutter, "xl") / 2;
  }

  img {
    max-width: 140px;
    box-shadow: $box-shadow;
  }
}

.profile__text {
  flex: 1 1 auto;
  padding: 0 map_get($grid-column-gutter, "sm");
  @include media-breakpoint-up(lg) {
    padding: 0 map_get($grid-column-gutter, "lg") 0 (map_get($grid-column-gutter, "lg") / 2);
  }
  @include media-breakpoint-up(xl) {
    padding: 0 map_get($grid-column-gutter, "xl") 0 (map_get($grid-column-gutter, "xl") / 2);
  }
}

.profile__body {
  padding: spacer(sm) (map_get($grid-column-gutter, "sm") / 2) spacer(md);
  @include media-breakpoint-up(md) {
    margin-left: percentage(3 / 12);
  }
  @include media-breakpoint-up(lg) {
    margin-left: percentage(2 / 12);
    padding: spacer(md) (map_get($grid-column-gutter, "lg") / 2) spacer(lg);
  }
  @include media-breakpoint-up(xl) {
    padding-left: spacer(md) (map_get($grid-column-gutter, "xl") / 2) spacer(xl);
  }
}
