.page-section {
  position: relative;
  padding-bottom: spacer(md);
  @include media-breakpoint-up(lg) {
    padding-bottom: spacer(lg);
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: spacer(xxl);
  }

  // Size background images
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

main .page-section + .page-section {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: $border-width-medium;
    background-color: $hsd-color-border-light;
    content: "";
  }
}

// Section Header
//

.page-section__header {
  margin-bottom: spacer(md);
  padding-top: spacer(xxl);
  @include media-breakpoint-up(lg) {
    margin-bottom: spacer(lg);
  }
  @include media-breakpoint-up(xl) {
    // max-width: map_get($container-max-widths, "lg");
    margin-bottom: spacer(xxl);
  }

  &:hover {
    .page-section__anchor {
      opacity: 1;
    }
  }

  h2 {
    position: relative;
    display: flex;
  }

  .lead {
    margin-top: spacer(sm);
  }
}

.page-section__anchor {
  display: inline-flex;
  align-items: center;
  margin-left: spacer(sm);
  color: $hsd-color-text-secondary;
  opacity: 0;
  @include media-breakpoint-down(md) {
    margin-left: spacer(sm);
  }
}

// Section Styles
//

.page-section--light,
.page-section__block--light,
.page-section--inverse,
.page-section__block--inverse,
.page-section--primary,
.page-section__block--primary {
  @include media-breakpoint-up(lg) {
    padding-top: map_get($container-gutters, "md");
    padding-bottom: map_get($container-gutters, "md");
  }
  @include media-breakpoint-up(xl) {
    padding-top: map_get($container-gutters, "lg");
    padding-bottom: map_get($container-gutters, "lg");
  }
}

.page-section--light,
.page-section__block--light {
  background-color: $hsd-color-gray-20;
}

.page-section--inverse,
.page-section__block--inverse {
  background-color: $hsd-color-gray-90;
  color: $hsd-color-text-inverse;
}

.page-section--primary,
.page-section__block--primary {
  background-color: $hsd-color-red;
  color: $hsd-color-text-inverse;
}
