// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   0.75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            $font-weight-bold !default;

$alert-bg-level:                    -10 !default;
$alert-color-level:                 6 !default;

//
// Base styles
//

.alert {
  position: relative;
  margin-bottom: $alert-margin-bottom;
  padding: $alert-padding-y $alert-padding-x;
  border-left: $border-width-thick solid transparent;
}

// Headings for larger alerts
.alert__heading {
  margin-bottom: $alert-padding-y;
  font-size: $font-size-base;
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert__link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert--dismissible {
  padding-right: ($font-size-base * 1.5) + $alert-padding-x * 2;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $hsd-color-theme {
  .alert--#{$color} {
    @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color($color), theme-color($color));
  }
}
