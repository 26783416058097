.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.icon--lg {
  width: 48px;
  height: 48px;
}
