// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 572px,
  md: 860px,
  lg: 1200px,
  xl: 1290px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Pullout container
//

$pullout-width: 1600px !default;

$container-gutters: (
  sm: 16px,
  md: 40px,
  lg: 80px,
  xl: 80px,
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;

/// The amount of space between columns at different screen sizes. To use just one size, set the variable to a number instead of a map.
/// @type Map | Length
$grid-column-gutter: (
  sm: 32px,
  lg: 48px,
  xl: 64px
);


/// The highest number of `.x-up` classes available when using the block grid CSS.
/// @type Number
$block-grid-max: 6;

// If a single value is passed as a gutter, convert it to a map so the code knows what to do with it
@if type-of($grid-column-gutter) == "number" {
  $grid-column-gutter: (small: $grid-column-gutter);
}
