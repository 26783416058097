.stage {
  position: relative;
  @include make-row();
}

.stage__content,
.stage__image {
  @include make-col-ready();
}

.stage__content {
  @include media-breakpoint-down(md) {
    width: auto;
    margin: -6rem auto 0;
  }
  @include media-breakpoint-up(md) {
    padding-top: spacer(lg);
  }
  @include media-breakpoint-up(lg) {
    padding-top: spacer(xl);
  }
  @include media-breakpoint-up(xl) {
    padding-top: spacer(xxl);
  }
}

.stage--center {
  position: relative;
  @extend .pull--x;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $hsd-color-base-white;
    content: "";
    opacity: 0.3;
  }

  .stage__content {
    padding-bottom: spacer(md);
    @include media-breakpoint-up(md) {
      padding-bottom: spacer(lg);
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: spacer(xxl);
    }
  }
}

.stage--callout-left,
.stage--callout-right {
  @include media-breakpoint-up(md) {
    padding: 0 0 spacer(lg);
  }
  @include media-breakpoint-up(lg) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 spacer(xl);
  }
  @include media-breakpoint-up(xl) {
    padding: 0 0 spacer(xxl);
  }

  .stage__content {
    padding: spacer(lg) spacer(lg);
    background-color: $hsd-color-gray-10;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      padding-top: map_get($container-gutters, "lg");
      // padding-right: map_get($container-gutters, "lg");
      padding-bottom: map_get($container-gutters, "lg");
    }
    @include media-breakpoint-up(xl) {
      padding-top: map_get($container-gutters, "xl");
      // padding-right: map_get($container-gutters, "xl");
      padding-bottom: map_get($container-gutters, "xl");
    }
  }

  .stage__image {
    min-height: 300px;
    margin: 0 (-1 * map_get($container-gutters, "sm"));
    @include media-breakpoint-down(md) {
      width: auto;
      padding: 0;
    }
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      overflow: hidden;
    }

    img {
      object-fit: cover;
    }
  }
}

.stage--callout-left {
  @include media-breakpoint-up(lg) {
    padding-right: percentage(6 / $grid-columns);
  }
  @include media-breakpoint-up(xl) {
    padding-right: percentage(8 / $grid-columns);
  }

  .stage__image {
    @include media-breakpoint-up(lg) {
      right: 0;
      padding-left: percentage(2 / $grid-columns);
    }
  }
}

.stage--callout-right {
  @include media-breakpoint-up(lg) {
    padding-left: percentage(6 / $grid-columns);
  }
  @include media-breakpoint-up(xl) {
    padding-left: percentage(8 / $grid-columns);
  }

  .stage__image {
    @include media-breakpoint-up(lg) {
      left: 0;
      padding-right: percentage(2 / $grid-columns);
    }
  }
}
