// stylelint-disable declaration-no-important, selector-list-comma-newline-after

@mixin typography {
  :root {
    font-feature-settings: "kern", "liga", "clig", "calt";
  }

  @supports (font-kerning: normal) and (font-variant-ligatures: common-ligatures contextual) {
    :root {
      font-kerning: normal;
      font-variant-ligatures: common-ligatures contextual;
      font-feature-settings: normal;
    }
  }

  //
  // Headings
  //

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    text-decoration: inherit;
    text-transform: inherit;
    color: $headings-color;
    @include media-breakpoint-down(md) {
      hyphens: auto;
    }

    small {
      display: block;
      margin-top: spacer(sm);
    }
  }

  h1,
  .h1 {
    font-size: $h1-font-size;
    line-height: $MinorThird;
    @include media-breakpoint-up(md) {
      font-size: $h1-font-size-md;
    }
  }

  h2,
  .h2 {
    font-size: $h2-font-size;
    line-height: $MajorThird;
    @include media-breakpoint-up(md) {
      font-size: $h2-font-size-md;
    }
  }

  h3,
  .h3 {
    font-size: $h3-font-size;
    line-height: $MajorThird;
    @include media-breakpoint-up(md) {
      font-size: $h3-font-size-md;
    }
  }

  h4,
  .h4 {
    font-size: $h4-font-size;
    letter-spacing: get-letter-spacing(0.125, 1.728);
    line-height: $MajorThird;
    @include media-breakpoint-up(md) {
      font-size: $h4-font-size-md;
    }
  }

  h5,
  .h5 {
    font-size: $h5-font-size;
    letter-spacing: get-letter-spacing(0.125, 1.44);
    line-height: $PerfectFourth;
    @include media-breakpoint-up(md) {
      font-size: $h5-font-size-md;
    }
  }

  h6,
  .h6 {
    font-size: $h6-font-size;
    letter-spacing: get-letter-spacing(0.125, 1.2);
    line-height: $AugmentedFourth;
    @include media-breakpoint-up(md) {
      font-size: $h6-font-size-md;
    }
  }

  // Lead
  .lead {
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
  }


  //
  // Display
  //

  .display--xl,
  .display--lg,
  .display--md,
  .display--sm {
    font-family: $font-family-display;
    font-weight: normal; // Reset
    @include media-breakpoint-down(sm) {
      hyphens: auto;
    }
  }

  .display--theme {
    font-family: $theme-font;
  }

  .display--xl {
    font-size: $display-font-size-xl;
    line-height: $MajorSecond;
    @include media-breakpoint-up(md) {
      font-size: $display-md-font-size-xl;
    }
    @include media-breakpoint-up(lg) {
      font-size: $display-lg-font-size-xl;
      line-height: $MinorSecond;
    }
  }

  .display--sm {
    font-size: $display-font-size-sm;
    line-height: $PerfectFifth;
    @include media-breakpoint-up(md) {
      font-size: $display-font-size-md;
    }
    @include media-breakpoint-up(lg) {
      font-size: $display-font-size-lg;
      line-height: $AugmentedFourth;
    }
  }

  .display--md {
    font-size: $display-md-font-size-sm;
    line-height: $AugmentedFourth;
    @include media-breakpoint-up(md) {
      font-size: $display-md-font-size-md;
    }
    @include media-breakpoint-up(lg) {
      font-size: $display-md-font-size-lg;
      line-height: $PerfectFourth;
    }
  }

  .display--lg {
    font-size: $display-lg-font-size-sm;
    line-height: $PerfectFourth;
    @include media-breakpoint-up(md) {
      font-size: $display-lg-font-size-md;
    }
    @include media-breakpoint-up(lg) {
      font-size: $display-lg-font-size-lg;
      line-height: $MajorThird;
    }
  }


  //
  // Horizontal rules
  //

  hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
    @include media-breakpoint-up(lg) {
      margin-top: $hr-margin-y * 2;
      margin-bottom: $hr-margin-y * 2;
    }
  }

  //
  // Emphasis
  //

  small,
  .small {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
  }

  mark,
  .mark {
    padding: $mark-padding;
    background-color: $mark-bg;
    color: $hsd-color-text-inverse;
  }


  //
  // Misc
  //

  // Builds on `abbr`
  abbr,
  .initialism {
    text-transform: uppercase;
    font-feature-settings: "kern", "liga", "clig", "calt", "c2sc", "smcp";

    &[title] {
      text-decoration: underline;
    }
  }


  //
  // Blockquotes
  //

  blockquote,
  .blockquote {
    position: relative;
    margin: 0;
    padding: spacer(lg) spacer(md);
    font-family: $font-family-serif;
    font-size: $font-size-base * $MajorSecond;
    @include media-breakpoint-up(lg) {
      padding: spacer(lg);
      font-size: $lead-font-size;
    }

    &::before {
      position: absolute;
      top: -5rem;
      left: spacer(xs);
      display: block;
      font-family: $font-family-serif;
      font-size: 8rem;
      line-height: $line-height-reset;
      color: $hsd-color-red;
      content: "„";
      opacity: 0.3;
      user-select: none;

      .theme-a &,
      .theme-d & {
        color: $hsd-color-yellow;
      }

      .theme-ei &,
      .theme-mv &,
      .theme-m & {
        color: $hsd-color-blue;
      }

      .theme-sk &,
      .theme-w & {
        color: $hsd-color-green;
      }
    }

    p {
      max-width: 34em;
    }

    &:not(:last-child) {
      margin-bottom: spacer(md);
    }
  }

  .blockquote__footer {
    position: relative;
    display: block;
    padding-top: spacer(sm);
    font-family: $font-family-base;
    font-size: 80%; // back to default font-size
    font-style: normal;
    color: $hsd-color-text-secondary;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: $border-width-medium;
      background-color: $hsd-color-text-secondary;
      content: "";
    }
  }

  .link,
  .text a:not([class]) {
    text-decoration: none;
    color: inherit;
    box-shadow: 0 $border-width-thin 0 $hsd-color-link;
    cursor: pointer;
    @include transition(box-shadow $duration-promptly ease-in-out);

    @include hover-focus {
      text-decoration: none;
      box-shadow: 0 $border-width-medium 0 $hsd-color-red;

      .theme-a &,
      .theme-d & {
        box-shadow: 0 $border-width-medium 0 $hsd-color-yellow;
      }

      .theme-ei &,
      .theme-mv &,
      .theme-m & {
        box-shadow: 0 $border-width-medium 0 $hsd-color-blue;
      }

      .theme-sk &,
      .theme-w & {
        box-shadow: 0 $border-width-medium 0 $hsd-color-green;
      }
    }
  }

  .link--arrow {
    position: relative;
    width: 100%;
    padding-right: calc(24px + #{spacer(xs)});

    @include hover-focus {
      &::after {
        right: 0;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      right: spacer(sm);
      display: block;
      width: 24px;
      height: 24px;
      margin-top: -12px;
      background-image: url("../icons/i-arrow_forward.svg");
      content: "";
      @include transition;
    }
  }
}

@if $css--global-reset == true {
  @include typography();
} @else {
  .css-boundary {
    @include typography();
  }
}
