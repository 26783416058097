// Breadcrumbs

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin: spacer(sm) 0;
  padding: 0;
  list-style: none;
}

.breadcrumb__item {
  display: flex;
  align-items: center;

  + .breadcrumb__item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb__item:hover::before {
    text-decoration: none;
  }

  &.active {
    font-weight: $font-weight-bold;
  }

  &:not(:last-child) {
    position: relative;
    margin-right: spacer(sm);
    padding-right: spacer(sm);

    &::after {
      position: absolute;
      top: 50%;
      right: -2px;
      display: inline-block; // Suppress underlining of the separator in modern browsers
      width: spacer(sm);
      height: spacer(sm);
      margin-top: (-0.5 * spacer(sm));
      padding-left: spacer(xs);
      transform: rotate(45deg);
      border-top: $border-width-thin solid $hsd-color-border;
      border-right: $border-width-thin solid $hsd-color-border;
      color: $hsd-color-gray-90;
      content: "";

      .footer--inverse & {
        border-top-color: $hsd-color-text-inverse;
        border-right-color: $hsd-color-text-inverse;
      }
    }
  }

  &--portal {
    font-weight: bold;
  }
}

.breadcrumb__link {
  display: inline-flex;
  padding: spacer(xs) 0;
  font-size: $font-size-small;

  @include hover-focus {
    text-decoration: underline;
  }
}
