// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.button {
  display: inline-flex;
  border: $btn-border-width solid transparent;
  background-color: transparent;
  font-weight: $btn-font-weight;
  text-align: center;
  text-decoration: none;
  color: $body-color;
  vertical-align: middle;
  user-select: none;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover {
    text-decoration: none;
    color: $body-color;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .button elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  svg,
  span {
    pointer-events: none;
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.button.disabled,
fieldset:disabled a.button {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $hsd-color-theme {
  .button--#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color, $value in $hsd-color-theme {
  .button--filled-#{$color} {
    @include button-variant($value, $value);
  }
}

.button--inverse {
  @include button-outline-variant($hsd-color-text-inverse);
}

.button--filled-inverse {
  @include button-variant($hsd-color-text-inverse, $hsd-color-text-inverse);
  // background-color: $hsd-text-inverse-color;
}

//
// Link buttons
//

// Make a button look and behave like a link
.button--link {
  font-weight: $font-weight-normal;
  color: $link-color;

  @include hover {
    text-decoration: $link-hover-decoration;
    color: $link-hover-color;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.button--lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.button--sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.button--expanded {
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding-right: 3rem;
  padding-left: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-color: $hsd-color-base-black;
  text-align: left;
  color: $hsd-color-link;

  &::after {
    position: absolute;
    top: 50%;
    right: spacer(sm);
    display: block;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-image: url("../icons/i-arrow_forward.svg");
    content: "";
    @include transition;
  }

  @include hover-focus {
    border-color: $hsd-color-red;

    .theme-a &,
    .theme-d & {
      border-color: $hsd-color-yellow;
    }

    .theme-ei &,
    .theme-mv &,
    .theme-m & {
      border-color: $hsd-color-blue;
    }

    .theme-sk &,
    .theme-w & {
      border-color: $hsd-color-green;
    }

    &::after {
      right: 0;
    }
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($hsd-color-link-focus, 0.5);
  }

  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    &:focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($hsd-color-link-focus, 0.5);
    }
  }

  // Vertically space out multiple block buttons
  + .button--expanded {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.button--block {
    width: 100%;
  }
}
