.widget {
  + .widget {
    margin-top: spacer(lg);
    @include media-breakpoint-up(lg) {
      margin-top: spacer(xl);
    }
    @include media-breakpoint-up(xl) {
      margin-top: spacer(xxl);
    }
  }
}

.widget__header {
  margin-bottom: spacer(sm);
  padding-bottom: spacer(sm);
  border-bottom: $border-width-thick solid $hsd-color-border;
}

.widget__title {
  @extend .heading--5;
}

.widget__body {
  padding: spacer(sm) 0;
}
