
.tabs {}

.tabs__list {
  &[role="tablist"] {
    display: flex;
    list-style: none;
  }
}

.tabs__list[role="tablist"] {
  display: flex;
  list-style: none;

  a {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    display: inline-flex;
    border-bottom: $btn-border-width solid transparent;
    background-color: transparent;
    font-size: $h4-font-size;
    font-weight: $btn-font-weight;
    text-align: center;
    text-decoration: none;
    color: $body-color;
    vertical-align: middle;
    user-select: none;
    @include media-breakpoint-up(md) {
      font-size: $h4-font-size-md;
    }
    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size-md;
    }

    &[aria-selected="true"] {
      border-bottom-color: $hsd-color-border;
    }
  }
}

.tabs__panel {
  padding: spacer(md) 0;
}
