.media {
  display: flex;
  align-items: flex-start;

  &__figure {
    flex-shrink: 0;
    margin-right: spacer(sm);
  }

  &__body {
    flex: 1;
    min-width: 0; // This fixes an issue with truncation inside flexbox due to the changes in flex basis (already in FFOX, coming to Chrome)
  }

  &__body,
  &__body > :last-child {
    margin-bottom: 0;
  }
}

.media--sm {
  .media__figure {
    margin-right: spacer(sm);
  }

  .media__figure--reverse {
    margin-left: spacer(sm);
  }
}

.media--lg {
  .media__figure {
    margin-right: spacer(lg);
  }

  .media__figure--reverse {
    margin-left: spacer(lg);
  }
}

.media--inline .media__body {
  flex: 0 1 auto;
}

.media--center {
  align-items: center;
}

.media__figure--reverse {
  margin: 0 0 0 spacer(sm);
}

@include media-breakpoint-down(md) {
  .media--responsive {
    display: block;

    .media__figure {
      margin: 0 0 spacer(sm);
    }
  }
}
