// hr,
// .divider {
//   height: $border-width-thin;
//   background-color: $body-color;
// }

// .divider--thick {
//   height: $border-width-thick;
//   background-color: $body-color;
// }

.dividers--y > * + * {
  border-top: $border-width-medium solid $hsd-color-border;
}

.dividers--light > * + * {
  border-color: $hsd-color-border-light;
}
