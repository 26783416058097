// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-bg:                    transparent !default;
$table-accent-bg:             rgba($hsd-color-base-black, 0.05) !default;
$table-hover-bg:              rgba($hsd-color-base-black, 0.075) !default;
$table-active-bg:             $table-hover-bg !default;

$table-head-bg:               $hsd-color-gray-30 !default;
$table-head-color:            $hsd-color-gray-90 !default;

$table-dark-bg:               $hsd-color-gray-90 !default;
$table-dark-accent-bg:        rgba($hsd-color-base-white, 0.05) !default;
$table-dark-hover-bg:         rgba($hsd-color-base-white, 0.075) !default;
$table-dark-border-color:     lighten($hsd-color-gray-90, 7.5%) !default;
$table-dark-color:            $body-bg !default;

$table-striped-order:         odd !default;

$table-caption-color:         $hsd-color-text-secondary !default;

//
// Basic Bootstrap table
//

.table {
  width: 100%;
  margin-bottom: spacer(md);
  border-bottom: $border-width-thin solid $hsd-color-border-light;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: spacer(sm);
    border-top: $border-width-thin solid $hsd-color-border-light;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  thead th {
    border-bottom: (2 * $border-width-thin) solid $hsd-color-border-light;
    vertical-align: bottom;
  }

  tbody + tbody {
    border-top: (2 * $border-width-thin) solid $hsd-color-border-light;
  }

  .table {
    background-color: $body-bg;
  }
}


//
// Condensed table w/ half padding
//

.table--sm {
  th,
  td {
    padding: spacer(xs);
  }
}


// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table--bordered {
  border: $border-width-thin solid $hsd-color-border-light;

  th,
  td {
    border: $border-width-thin solid $hsd-color-border-light;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $border-width-thin);
    }
  }
}

.table--borderless {
  &,
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table--striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table--hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// @each $color, $value in $theme-colors {
//   @include table-row-variant($color, theme-color-level($color, $table-bg-level), theme-color-level($color, $table-border-level));
// }

@include table-row-variant(active, $table-active-bg);


// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead--dark {
    th {
      border-color: $table-dark-border-color;
      background-color: $table-dark-bg;
      color: $table-dark-color;
    }
  }

  .thead--light {
    th {
      border-color: $hsd-color-border;
      background-color: $table-head-bg;
      color: $table-head-color;
    }
  }
}

// .table--dark {
//   background-color: $table-dark-bg;
//   color: $table-dark-color;
//
//   th,
//   td,
//   thead th {
//     border-color: $table-dark-border-color;
//   }
//
//   &.table--bordered {
//     border: 0;
//   }
//
//   &.table--striped {
//     tbody tr:nth-of-type(odd) {
//       background-color: $table-dark-accent-bg;
//     }
//   }
//
//   &.table--hover {
//     tbody tr {
//       @include hover {
//         background-color: $table-dark-hover-bg;
//       }
//     }
//   }
// }


// Responsive tables
//
// Generate series of `.table--responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table--responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table--bordered {
          border: 0;
        }
      }
    }
  }
}
