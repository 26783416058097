// stylelint-disable declaration-no-important

// Typography

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }

  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }
}


// Text truncate
// Requires inline-block or block for proper styling

@mixin text-truncate() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


@mixin text-inverse() {
  color: $hsd-color-text-inverse;

  h1,
  .heading--1,
  h2,
  .heading--2,
  h3,
  .heading--3,
  h4,
  .heading--4,
  h5,
  .heading--5,
  h6,
  .heading--6,
  p {
    color: $hsd-color-text-inverse;
  }

  a:not([class]) {
    text-decoration: none;
    color: $hsd-color-text-inverse;
    box-shadow: 0 $border-width-thin 0 $hsd-color-text-inverse;

    @include hover-focus {
      box-shadow: 0 $border-width-medium 0 $hsd-color-text-inverse;
    }
  }

  .button--link {
    color: $hsd-color-text-inverse;

    @include hover-focus {
      color: $hsd-color-text-inverse;
    }
  }

  .list--marker li::before,
  .text ul li::before {
    color: $hsd-color-text-inverse;
  }
}
