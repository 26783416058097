.partners {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.partners__item {
  margin-bottom: spacer(lg);

  &:not(:last-child) {
    margin-right: spacer(lg);
  }

  a {
    display: block;
    transform: scale(1);
    @include transition();

    @include hover-focus {
      transform: scale(1.25);
    }
  }
}
