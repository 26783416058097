//
// Margin
//

.margined,
.margined--t {
  padding-top: spacer(md);
  @include media-breakpoint-up(lg) {
    padding-top: spacer(lg);
  }
}

.margined,
.margined--b {
  padding-bottom: spacer(md);
  @include media-breakpoint-up(lg) {
    padding-bottom: spacer(lg);
  }
}


//
// Padding
//

.padded,
.padded--t {
  padding-top: spacer(md);
  @include media-breakpoint-up(lg) {
    padding-top: spacer(lg);
  }
  @include media-breakpoint-up(xl) {
    padding-top: spacer(xxl);
  }
}


.padded,
.padded--b {
  padding-bottom: spacer(md);
  @include media-breakpoint-up(lg) {
    padding-bottom: spacer(lg);
  }
  @include media-breakpoint-up(xl) {
    padding-top: spacer(xxl);
  }
}


// stylelint-disable declaration-no-important

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{if($infix == "", "-", "-#{$infix}")}-#{$size} {
          #{$prop}: $length !important;
        }

        .#{$abbrev}t#{if($infix == "", "-", "-#{$infix}")}-#{$size},
        .#{$abbrev}y#{if($infix == "", "-", "-#{$infix}")}-#{$size} {
          #{$prop}-top: $length !important;
        }

        .#{$abbrev}r#{if($infix == "", "-", "-#{$infix}")}-#{$size},
        .#{$abbrev}x#{if($infix == "", "-", "-#{$infix}")}-#{$size} {
          #{$prop}-right: $length !important;
        }

        .#{$abbrev}b#{if($infix == "", "-", "-#{$infix}")}-#{$size},
        .#{$abbrev}y#{if($infix == "", "-", "-#{$infix}")}-#{$size} {
          #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l#{if($infix == "", "-", "-#{$infix}")}-#{$size},
        .#{$abbrev}x#{if($infix == "", "-", "-#{$infix}")}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }
  }
}
