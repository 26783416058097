.event {
  display: flex;
  flex-direction: column;
}

.event__meta {
  display: flex;
  flex-wrap: wrap;
  order: -1;
  font-size: $font-size-small;
  color: $hsd-color-text-secondary;

  span {
    margin: 0 spacer(xs);
  }
}

.event__title {
  @extend .heading--6;
}
