// Responsive images (ensure images don't scale beyond their parents)
.img--fluid {
  @include img-fluid;
}

.figure {
  display: inline-block;

  figcaption,
  &__caption {
    margin-top: spacing(sm);
    font-size: $font-size-small;
    color: $hsd-color-text-secondary;
  }
}

.picture--styled,
.picture--border {
  position: relative;

  &::before {
    position: absolute;
    top: spacer(sm);
    left: spacer(sm);
    display: block;
    width: 100%;
    height: 100%;
    background-color: $hsd-color-red;
    content: "";

    .theme-a &,
    .theme-d & {
      background-color: $hsd-color-yellow;
    }

    .theme-ei &,
    .theme-mv &,
    .theme-m & {
      background-color: $hsd-color-blue;
    }

    .theme-sk &,
    .theme-w & {
      background-color: $hsd-color-green;
    }
  }

  img {
    position: relative;
  }
}

.picture--styled {
  img {
    mix-blend-mode: darken;

    .theme-sk &,
    .theme-w & {
      mix-blend-mode: hard-light;
    }
  }
}

.img-link {
  display: block;

  img {
    @include box-move-transition;
  }
}

picture {
  display: block;
}
