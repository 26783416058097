.youtube {
  position: relative;
  cursor: pointer;

  @include hover {
    .youtube__svg {
      transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1), fill-opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
      fill: #f00;
      fill-opacity: 1;
    }
  }
}

.youtube__preview {
  position: relative;
}

.youtube__button {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 68px;
  height: 48px;
  margin-top: -24px;
  margin-left: -34px;
  padding: 0;
  border: 0;
  background: transparent;
  content: "";

  .youtube__svg {
    transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
    fill: #212121;
    fill-opacity: 0.8;
  }
}

.youtube__info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: spacer(md);
  background-color: rgba($hsd-color-base-white, 0.8);
  font-size: $font-size-small;
}

.youtube__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
