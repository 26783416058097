.faculties {
  @include make-row();
  margin: 0;
  padding: 0;
  list-style: none;

  img {
    display: block;
    width: 100%;
    height: auto;
    fill: inherit;
  }
}

.faculties__item {
  @include make-col-ready();
  margin-bottom: spacer(md);
  @include media-breakpoint-up(md) {
    @include make-col(1, 2);
  }

  &--a,
  &--d {
    .faculties__logo {
      background-color: $hsd-color-yellow;
      color: $hsd-color-base-black;
    }

    a:hover,
    a:focus {
      @include hover-focus {
        .faculties__logo {
          background-color: $hsd-color-yellow;
        }
      }
    }
  }


  &--ei,
  &--mv,
  &--m {
    .faculties__logo {
      background-color: $hsd-color-blue;
    }

    a:hover,
    a:focus {
      @include hover-focus {
        .faculties__logo {
          background-color: $hsd-color-blue;
        }
      }
    }
  }

  &--sk,
  &--w {
    .faculties__logo {
      background-color: $hsd-color-green;
    }

    a:hover,
    a:focus {
      @include hover-focus {
        .faculties__logo {
          background-color: $hsd-color-green;
        }
      }
    }
  }
}

.faculties__link {
  display: flex;
  align-items: center;
  background-color: $hsd-color-base-white;
  text-decoration: none;
}

.faculties__logo {
  width: 60px;
  min-width: 60px;
  @include media-breakpoint-up(lg) {
    width: 80px;
    min-width: 80px;
  }
}

.faculties__name {
  padding: spacer(xs) spacer(sm);
  @include media-breakpoint-up(lg) {
    padding: spacer(sm);
  }
}

.faculties--hover {
  .faculties__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-position: center;
    content: "";
    z-index: -1;
    pointer-events: none;
  }

  .faculties__link {
    @include hover-focus {
      .faculties__bg {
        display: block;
      }
    }
  }
}
