
// Calculated color with https://codepen.io/sosuke/pen/Pjoqqp
@mixin color-svg($color: "red") {
  @if $color == "white" {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(291deg) brightness(103%) contrast(103%);
  }

  @if $color == "red" {
    filter: invert(16%) sepia(72%) saturate(7169%) hue-rotate(358deg) brightness(99%) contrast(114%);
  }

  @if $color == "green" {
    filter: invert(65%) sepia(20%) saturate(1468%) hue-rotate(53deg) brightness(91%) contrast(83%);
  }

  @if $color == "blue" {
    filter: invert(54%) sepia(31%) saturate(7298%) hue-rotate(159deg) brightness(101%) contrast(101%);
  }

  @if $color == "yellow" {
    filter: invert(87%) sepia(62%) saturate(2148%) hue-rotate(358deg) brightness(107%) contrast(101%);
  }
}
