.news {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: spacer(md);

  &:hover,
  &:focus-within {
    .news__title a::before {
      width: 100%;
      background-color: $hsd-color-base-black;
    }
  }
}

.news__media {
  width: 100%;
}

.news__header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: spacer(sm);
}

.news__title {
  width: 100%;
  padding-top: spacer(md);
  font-size: $h4-font-size;
  @include media-breakpoint-up(md) {
    font-size: $h4-font-size-md;
  }

  a {
    text-decoration: none;
    box-shadow: none;

    @include hover-focus {
      color: $hsd-color-link;
      box-shadow: none;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      z-index: 1;
    }

    &::before {
      position: relative;
      top: -1 * spacer(md);
      display: block;
      width: 6rem;
      height: $border-width-thick;
      margin-top: auto;
      background-color: $hsd-color-red;
      content: "";
      @include transition;

      .theme-a &,
      .theme-d & {
        background-color: $hsd-color-yellow;
      }

      .theme-ei &,
      .theme-mv &,
      .theme-m & {
        background-color: $hsd-color-blue;
      }

      .theme-sk &,
      .theme-w & {
        background-color: $hsd-color-green;
      }
    }
  }
}

.news__categories {
  display: flex;
  list-style: none;

  li {
    padding: spacer(sm) spacer(md) spacer(sm) 0;
  }
}

.news__published {
  display: inline-block;
  margin-bottom: spacer(sm);
  font-size: $font-size-small;
  line-height: 1;
  color: $hsd-color-text-secondary;
  z-index: 1;
}

.news__lead {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &:not(:last-child) {
    padding-bottom: 0;
  }
}

.news__more {
  width: 100%;
  margin-top: spacer(md);
  @media (hover: hover) {
    display: none;
  }
}

.news--horizontal {
  display: flex;
  @include media-breakpoint-up(md) {
    .news__meta {
      margin-bottom: 0;
    }

    .news__media {
      width: percentage(4 / 12);
      max-width: percentage(4 / 12);
      padding-right: map-get($grid-column-gutter, "lg") / 2;
    }

    .news__content {
      width: percentage(8 / 12);
      max-width: percentage(8 / 12);
    }
  }
  @include media-breakpoint-up(xl) {
    .news__media {
      padding-right: map-get($grid-column-gutter, "xl") / 2;
    }

    .news__content {
      padding-left: map-get($grid-column-gutter, "xl") / 2;
    }
  }
}
