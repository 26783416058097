.navigator {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $hsd-color-gray-20;
}

.navigator__bar,
.navigator__content {
  @extend .container;
}

.navigator__bar {
  display: flex;
  justify-content: space-between;
}

.navigator__nav {
  display: flex;
  justify-content: flex-start;
  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
    max-height: 2.5rem;
  }
}

.navigator__options {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigator__item {
  &:not(:last-child) {
    margin-right: spacer(sm);
  }
}

.navigator__link {
  display: block;
  padding: 9px 0;
  border: none;
  background-color: transparent;
  font-size: $font-size-small;
  line-height: 1;
  text-decoration: none;

  @include hover-focus {
    box-shadow: inset 0 (-1 * $border-width-thick) 0 $hsd-color-red;
  }

  &[aria-expanded="true"] {
    box-shadow: inset 0 (-1 * $border-width-thick) 0 $hsd-color-red;
  }
}

.navigator__options--left {
  flex: 1;
}

.navigator__content {
  padding-top: spacer(xl);
  padding-bottom: spacer(md);
}
