.border {
  border: $border-width-thin solid $hsd-color-border !important;
}

.border--t,
.border--y {
  border-top: $border-width-thin solid $hsd-color-border !important;
}

.border--r,
.border--x {
  border-right: $border-width-thin solid $hsd-color-border !important;
}

.border--b,
.border--y {
  border-bottom: $border-width-thin solid $hsd-color-border !important;
}

.border--l,
.border--x {
  border-left: $border-width-thin solid $hsd-color-border !important;
}
