.toc {
  margin: 0;
  padding-top: spacer(sm);
  padding-bottom: spacer(sm);
}

.toc__button {
  padding: 0;
  border: none;
  appearance: none;
  background-color: transparent;
  color: $hsd-color-text-secondary;
}

.toc__list {
  margin: spacer(md) 0 0;
  padding: 0;
  list-style-position: inside;
}

.toc__item {
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
