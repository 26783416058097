.notification-list {
  @include make-container();
  padding-top: spacer(sm);
  padding-bottom: spacer(sm);

  &:empty {
    display: none;
  }
}

.notification--warning {
}
