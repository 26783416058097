
.footer {
  @extend .container;
  border-top: $border-width-thick solid $hsd-color-border;
}

.footer__row {
  padding-top: spacer(lg);
  padding-bottom: spacer(lg);
  @include media-breakpoint-up(lg) {
    padding-top: spacer(xl);
    padding-bottom: spacer(xl);
  }
}
