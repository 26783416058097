//
// Text
//

.text {
  max-width: 46em;
  hyphens: auto;
  @each $breakpoint, $value in $grid-column-gutter {
    @include media-breakpoint-up($breakpoint) {
      grid-column-gap: $value;
    }
  }

  & + .text {
    margin-bottom: spacer(md);
  }

  h1,
  .heading--1 {
    margin-top: 4rem;
  }

  h2,
  .heading--2 {
    margin-top: 3.5rem;
  }

  h3,
  .heading--3 {
    margin-top: 3rem;
  }

  h4,
  .heading--4 {
    margin-top: 2rem;
  }

  h5,
  .heading--5 {
    margin-top: 1.5rem;
  }

  h6,
  .heading--6 {
    margin-top: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  ul:not(:last-child),
  ol:not(:last-child),
  dl:not(:last-child) {
    margin-bottom: 1rem;
  }

  + .alert {
    margin-top: spacer(md);
  }

  a[href*="//"]:not([href*="hs-duesseldorf.de"])::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-left: spacer(xs);
    background: transparent url("../icons/i-external.svg") no-repeat;
    background-size: 1rem 1rem;
    content: "";
    vertical-align: middle;
  }
}

.text--serif {
  font-family: $font-family-serif;
  font-size: 1.0625rem; // 18/16
  font-smoothing: initial;
  line-height: 1.4375; // 23/16
  @include media-breakpoint-up(lg) {
    font-size: 1.1875rem; // 21/16
  }
}

//
// File types
//

.text a[href$=".pdf"],
.text a[href$=".doc"],
.text a[href$=".docx"],
.text a[href$=".ppt"],
.text a[href$=".pptx"],
.text a[href$=".xls"],
.text a[href$=".xlsx"] {
  &::after {
    display: inline-block;
    margin-left: 0.2em;
    font-variant: small-caps;
    color: $hsd-color-text-secondary;
  }
}

.text a[href$=".pdf"]::after {
  content: "(pdf)";
}

.text a[href$=".doc"]::after,
.text a[href$=".docx"]::after {
  content: "(doc)";
}

.text a[href$=".ppt"]::after,
.text a[href$=".pptx"]::after {
  content: "(doc)";
}

.text a[href$=".xls"]::after,
.text a[href$=".xlsx"]::after {
  content: "(xls)";
}

//
// Multi column
//

.text--md-up-2 {
  @include media-breakpoint-up(md) {
    max-width: none;
    columns: 2;
  }
}

.text--md-up-3 {
  @include media-breakpoint-up(md) {
    max-width: none;
    columns: 3;
  }
}

.text--lg-up-2 {
  @include media-breakpoint-up(lg) {
    max-width: none;
    columns: 2;
  }
}

.text--lg-up-3 {
  @include media-breakpoint-up(lg) {
    max-width: none;
    columns: 3;
  }
}
