
@import "variables/colors";
@import "variables/scale";
@import "variables/size";
@import "variables/grid";
@import "variables/theme";


//
//  Body
//
// Settings for the `<body>` element.

$body-bg:                   $hsd-color-base-white !default;
$body-color:                $hsd-color-text !default;


//
// Links
//
// Style anchor elements.

$link-color:                $hsd-color-link !default;
$link-decoration:           underline !default;
$link-hover-color:          $hsd-color-link-hover !default;
$link-hover-decoration:     underline !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              $PerfectFourth !default; // 1.3334
$line-height-sm:              $PerfectFifth !default; // 1.5

$border-width-thin:           1px !default;
$border-width-medium:         2px !default;
$border-width-thick:          4px !default;

$border-radius:               0.25rem !default;
$border-radius-lg:            0.3rem !default;
$border-radius-sm:            0.2rem !default;

$box-shadow-sm:               0 0.125rem 0.25rem rgba($hsd-color-base-black, 0.075), 0 0.125rem 0.25rem rgba($hsd-color-base-black, 0.24) !default;
$box-shadow:                  0 0.5rem 1rem rgba($hsd-color-base-black, 0.075), 0 0.5rem 1rem rgba($hsd-color-base-black, 0.12) !default;
$box-shadow-lg:               0 1rem 3rem rgba($hsd-color-base-black, 0.15), 0 1rem 3rem rgba($hsd-color-base-black, 0.1) !default;

$component-active-color:      $hsd-color-base-white !default;
$component-active-bg:         $hsd-color-red !default;

$caret-width:                 0.3em !default;


// Duration
$duration-instantly: 0s !default;
$duration-immediately: 0.05s !default;
$duration-quickly: 0.1s !default;
$duration-promptly: 0.2s !default;
$duration-slowly: 0.4s !default;
$duration-paused: 3.2s !default;

$transition-base:             all $duration-promptly ease-in-out !default;
$transition-fade:             opacity $duration-quickly linear !default;
$transition-collapse:         height $duration-slowly ease !default;


//
//  Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-serif:           "Times New Roman", sans-serif !default;
$font-family-display:         "hsd sans", Arial;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-small:             0.875rem !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            600 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-reset:           1 !default;
$line-height-base:            $PerfectFifth !default; // 1.5

$h6-font-size:                $font-size-base !default; // 1rem
$h5-font-size:                ($h6-font-size * $MajorSecond) !default;   // 1.44rem
$h4-font-size:                ($h5-font-size * $MajorSecond) !default;   // 1.728rem
$h3-font-size:                ($h4-font-size * $MajorSecond) !default;   // 2.074rem
$h2-font-size:                ($h3-font-size * $MajorSecond) !default;   // 2.488rem
$h1-font-size:                ($h2-font-size * $MajorSecond) !default;   // 2.986rem

$h6-font-size-md:             $font-size-base !default; // 1rem
$h5-font-size-md:             ($h6-font-size-md * $MinorThird) !default;   // 1.44rem
$h4-font-size-md:             ($h5-font-size-md * $MinorThird) !default;   // 1.728rem
$h3-font-size-md:             ($h4-font-size-md * $MinorThird) !default;   // 2.074rem
$h2-font-size-md:             ($h3-font-size-md * $MinorThird) !default;   // 2.488rem
$h1-font-size-md:             ($h2-font-size-md * $MinorThird) !default;   // 2.986rem

$headings-margin-bottom:      spacer(sm) !default;
$headings-font-family:        $font-family-base !default;
$headings-color:              $hsd-color-heading !default;

$display-font-size-sm:        1rem !default;
$display-font-size-md:        ($display-font-size-sm * $MinorThird) !default;
$display-font-size-lg:        ($display-font-size-md * $MinorThird) !default;
$display-font-size-xl:        ($display-font-size-lg * $MinorThird) !default;

$display-md-font-size-sm:     1.5rem !default;
$display-md-font-size-md:     ($display-md-font-size-sm * $MajorThird) !default;
$display-md-font-size-lg:     ($display-md-font-size-md * $MajorThird) !default;
$display-md-font-size-xl:     ($display-md-font-size-lg * $MajorThird) !default;

$display-lg-font-size-sm:     2rem !default;
$display-lg-font-size-md:     ($display-lg-font-size-sm * $PerfectFourth) !default;
$display-lg-font-size-lg:     ($display-lg-font-size-md * $PerfectFourth) !default;
$display-lg-font-size-xl:     ($display-lg-font-size-lg * $PerfectFourth) !default;

$lead-font-size:              1.5rem !default;
$lead-font-weight:            $font-weight-normal !default;

$hr-border-color:             rgba($hsd-color-base-black, 0.1) !default;
$hr-border-width:             $border-width-thin !default;

$mark-padding:                0.2em !default;

$kbd-box-shadow:              inset 0 -0.1rem 0 rgba($hsd-color-base-black, 0.25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$mark-bg:                     $hsd-color-red !default;

$hr-margin-y:                 spacer(md) !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.375rem !default;
$input-btn-padding-x:         0.75rem !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       0.2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      0.25rem !default;
$input-btn-padding-x-sm:      0.5rem !default;
$input-btn-font-size-sm:      $font-size-small !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      0.5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-base !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width-medium !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-bold !default;
$btn-box-shadow:              inset 0 1px 0 rgba($hsd-color-base-white, 0.15), 0 1px 1px rgba($hsd-color-base-black, 0.075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        0.65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($hsd-color-base-black, 0.125) !default;

$btn-link-disabled-color:     $hsd-color-gray-90 !default;

$btn-block-spacing-y:         0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-transition:              color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
