//
// Body
//

// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use the
//    the `inherit` value on things like `<th>` elements.

/// Body styles
/// @access private
/// @group global-body
@mixin css-body {
  margin: 0; // 1
  background-color: $body-bg; // 2
  font-family: $font-family-base;
  font-size: $font-size-base;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  text-align: left; // 3
  color: $body-color;
}

@if $css--global-reset == true {
  body {
    @include css-body();

    & > main {
      flex: 1 0 auto;
    }
  }
} @else {
  .css-boundary {
    @include css-body();
  }
}
