// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container,
.container-padded {
  @include make-container();
}

.container-padded {
  @include make-container-padded();
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.
.container--fluid {
  @include make-container();
}

// Grid
//

.grid {
  @include make-grid();
}

.grid--no-gutters {
  grid-row-gap: 0;
  grid-column-gap: 0;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @for $i from 1 through $block-grid-max {
      .grid--#{$breakpoint}-up-#{$i} {
        grid-template-columns: repeat($i, 1fr);
      }
    }
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row();
}

@include row-collapse();

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.row--no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Remove the horizontal padding from all immediate children columns, but not
// the negative margin from default .row.
.row--with-blocks {
  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();
