//
// Collapse
//

.accordion {
  width: 100%;
}

.accordion__item:not(:first-child) {
  border-top: $border-width-thin solid $hsd-color-border-light;
}

.accordion__header {
  position: relative;
  width: 100%;

  button {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: spacer(sm) 0;
    appearance: none;
    border: 0;
    background-color: transparent;
    font-weight: inherit;
    text-align: initial;
    color: $hsd-color-heading;

    @include hover-focus {
      background-color: $hsd-color-link-hover-bg;
    }

    &:focus {
      outline: none;
      // @include focus-outline();
    }

    &[aria-expanded="true"] {
      &::before {
        transform: rotate(0deg);
      }
    }

    &::before {
      display: block;
      width: spacer(lg);
      height: spacer(lg);
      margin-right: spacer(sm);
      transform: rotate(-90deg);
      background: transparent url("../icons/i-expand_more.svg") top left no-repeat;
      content: "";
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
    }
  }
}

.accordion__body {
  padding: spacer(sm) 0;
  @include media-breakpoint-up(lg) {
    padding: spacer(md) 0;
  }
}
