.navbar {
  width: 100%;
  margin-top: spacer(md);
  border-bottom: $border-width-medium solid $hsd-color-border;
  @include media-breakpoint-up(lg) {
    margin-top: spacer(lg);
  }
}

.navbar__items {
  padding: 0;
  list-style: none;
  @include media-breakpoint-down(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.navbar__item:not(:last-child) {
  margin-right: spacer(sm);
}

.navbar__link {
  position: relative;
  display: block;
  padding: spacer(sm);
  font-size: $h5-font-size;
  font-weight: $font-weight-bold;
  line-height: 1;
  text-decoration: none;
  color: $hsd-color-text-secondary;
  @include media-breakpoint-up(md) {
    padding: spacer(sm);
  }

  @include hover-focus {
    text-decoration: none;
    color: $hsd-color-base-black;
  }

  &[aria-controls] {
    padding-right: 30px;

    &::after {
      position: absolute;
      top: 6px;
      right: 0.25rem;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background: url("../icons/i-drop_down.svg") top left no-repeat;
      content: "";
    }
  }

  &[aria-expanded="true"]::after {
    transform: rotate(180deg);
  }

  .navbar__item.active & {
    color: $hsd-color-ink;
  }

  &[aria-expanded="true"] {
    box-shadow: inset 0 $border-width-medium 0 $hsd-color-base-black;
    @include media-breakpoint-up(md) {
      background-color: $hsd-color-gray-10;
    }
  }
}

.navbar__dropdown {
  position: relative;
  padding: spacer(xs);
  background-color: $hsd-color-gray-10;
  @include media-breakpoint-up(md) {
    padding: spacer(sm);
  }

  &:not(.show) {
    display: none;
  }
}

.navbar__close {
  position: absolute;
  top: spacer(xs);
  right: spacer(xs);
  @include media-breakpoint-up(md) {
    top: spacer(md);
    right: spacer(md);
  }
}

.navbar__groups {
  padding: 0 spacer(sm);
  opacity: 1;
  @include transition(opacity 0 linear);
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.navbar__header {
  display: flex;
  align-items: center;
  padding: spacer(sm) spacer(sm) spacer(sm);
  opacity: 1;
  @include media-breakpoint-up(lg) {
    padding-top: spacer(md);
  }

  h3 {
    margin-right: spacer(md);
  }

  button {
    margin-left: auto;
  }
}

.navbar__menu {
  margin-bottom: spacer(sm);
  @include media-breakpoint-up(lg) {
    margin-top: spacer(md);
    margin-bottom: spacer(md);
  }

  &:not(:first-child) {
    @include media-breakpoint-down(lg) {
      margin-top: spacer(md);
    }
  }
}
