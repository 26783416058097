// stylelint-disable declaration-no-important

//
// Text
//

// Alignment
.text--justify {
  text-align: justify !important;
}

.text--nowrap {
  white-space: nowrap !important;
}

.text--truncate {
  @include text-truncate;
}

// Responsive alignment
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text-align#{if($infix == "", "-", "-#{$infix}")}-left {
      text-align: left !important;
    }

    .text-align#{if($infix == "", "-", "-#{$infix}")}-right {
      text-align: right !important;
    }

    .text-align#{if($infix == "", "-", "-#{$infix}")}-center {
      text-align: center !important;
    }
  }
}

//
// Contextual colors
//

.highlight {
  margin-left: -1 * spacer(md);
  padding-left: spacer(md);
  box-shadow: inset $border-width-thick 0 0 $hsd-color-red;
  @include media-breakpoint-up(lg) {
    margin-left: -1 * spacer(lg);
    padding-left: spacer(lg);
  }

  .theme-a &,
  .theme-d & {
    box-shadow: inset $border-width-thick 0 0 $hsd-color-yellow;
  }

  .theme-ei &,
  .theme-mv &,
  .theme-m & {
    box-shadow: inset $border-width-thick 0 0 $hsd-color-blue;
  }

  .theme-sk &,
  .theme-w & {
    box-shadow: inset $border-width-thick 0 0 $hsd-color-green;
  }
}

.body-text--small {
  font-size: $font-size-small;
}

.color--muted {
  opacity: 0.8;
}

.color--primary {
  color: $hsd-color-red;
}

.color--secondary {
  color: $hsd-color-text-secondary;
}

.color--theme {
  color: $hsd-color-red;

  .theme-a &,
  .theme-d & {
    color: $hsd-color-yellow;
  }

  .theme-ei &,
  .theme-mv &,
  .theme-m & {
    color: $hsd-color-blue;
  }

  .theme-sk &,
  .theme-w & {
    color: $hsd-color-green;
  }
}
