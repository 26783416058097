// Front W
// Author: Florian Bögner (florianboegner.com)

@if not variable-exists('css--font-face') or $css--font-face == true {
  @font-face {
    font-family: "hsd sans";
    font-display: block;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    // IE9 Compat Modes
    src: url("../fonts/hsdsans-regular-webfont.eot");
    src:
      // Super Modern Browsers
      url("../fonts/hsdsans-regular-webfont.woff2") format("woff2"),
      // Pretty Modern Browsers
      url("../fonts/hsdsans-regular-webfont.woff") format("woff");
  }


  @font-face {
    font-family: "hsd sans economy";
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    src: url("../fonts/hsdsans-wirtschaft-webfont.eot");
    src:
      url("../fonts/hsdsans-wirtschaft-webfont.woff2") format("woff2"),
      url("../fonts/hsdsans-wirtschaft-webfont.woff") format("woff");
  }
}
