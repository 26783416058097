// Carousel

$carousel-control-color:             $hsd-color-base-black !default;
$carousel-control-width:             15% !default;
$carousel-control-transition:        opacity 0.15s ease !default;

$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-transition:      opacity 0.6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $hsd-color-base-white !default;

$carousel-transition:                transform 0.6s ease !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//    even when their scroll action started on a carousel, but for compatibility (with Firefox)
//    we're preventing all actions instead
// 2. The .carousel__item-left and .carousel__item-right is used to indicate where
//    the active slide is heading.
// 3. .active.carousel__item is the current slide.
// 4. .active.carousel__item-left and .active.carousel__item-right is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel__item-next.carousel__item-left and .carousel__item-prev.carousel__item-right
//    is the upcoming slide in transition.

.carousel {
  display: flex;
  flex-direction: column;
}

.carousel.pointer-event {
  touch-action: none;
}

.carousel__inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel__item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel__item.active,
.carousel__item-next,
.carousel__item-prev {
  display: block;
  @include transition($carousel-transition);
}

.carousel__item-next,
.carousel__item-prev {
  position: absolute;
  top: 0;
}

.carousel__item-next.carousel__item-left,
.carousel__item-prev.carousel__item-right {
  transform: translateX(0);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(0, 0, 0);
  }
}

.carousel__item-next,
.active.carousel__item-right {
  transform: translateX(100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel__item-prev,
.active.carousel__item-left {
  transform: translateX(-100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(-100%, 0, 0);
  }
}


//
// Alternate transitions
//

.carousel--fade {
  .carousel__item {
    transform: none;
    transition-property: opacity;
    opacity: 0;
  }

  .carousel__item.active,
  .carousel__item-next.carousel__item-left,
  .carousel__item-prev.carousel__item-right {
    opacity: 1;
  }

  .active.carousel__item-left,
  .active.carousel__item-right {
    opacity: 0;
  }
}

.carousel__outer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  order: -1;
}

//
// Left/right controls for nav
//

.carousel__controls {
  display: flex;
  justify-content: flex-end;
}

.carousel__control-prev,
.carousel__control-next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacer(sm);
  background-color: $hsd-color-base-white;
  text-align: center;
  color: $carousel-control-color;
  @include transition($carousel-control-transition);

  // Hover/focus state
  @include hover-focus {
    outline: 0;
    text-decoration: none;
    color: $carousel-control-color;
  }
}

.carousel__control-prev {
  left: 0;
}

.carousel__control-next {
  right: 0;
}

// Icons for within
.carousel__control-prev-icon,
.carousel__control-next-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel__control-prev-icon {
  background-image: url("../icons/i-arrow_left.svg");
}

.carousel__control-next-icon {
  background-image: url("../icons/i-arrow_right.svg");
}


// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel__indicators {
  display: flex;
  flex: 1 0 0;
  justify-content: flex-start;
  padding-left: 0; // override <ol> default
  list-style: none;
  z-index: 15;

  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: $border-width-medium;
    // Use transparent borders to increase the hit area by 10px on top and bottom.
    border-top: $carousel-indicator-hit-area-height solid transparent;
    border-bottom: $carousel-indicator-hit-area-height solid transparent;
    background-color: $hsd-color-gray-40;
    text-indent: -999999em;
    cursor: pointer;
    background-clip: padding-box;
    opacity: 0.5;
    @include transition($carousel-indicator-transition);
  }

  .active {
    background-color: $hsd-color-base-black;
    opacity: 1;
  }
}


// Optional captions
//
//

.carousel__caption {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: 20px;
  left: ((100% - $carousel-caption-width) / 2);
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: $carousel-caption-color;
  z-index: 10;
}
