// stylelint-disable property-blacklist
@mixin transition($transition...) {
  @if length($transition) == 0 {
    transition: $transition-base;
  } @else {
    transition: $transition;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

@mixin box-move($box-shadow-color: $hsd-color-base-black) {
  transform: translate(5px, -4px);
  box-shadow: -1px 1px 0 $hsd-color-base-white, -5px 4px 0 $box-shadow-color;
}

@mixin box-move-transition() {
  transition: 0.3s, transform 0.3s;

  @include hover-focus {
    transition: 0.3s, transform 0.3s;
    @include box-move();
  };
}
