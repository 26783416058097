// stylelint-disable selector-no-qualifying-type

// Make the div behave like a button
.button-group,
.button-group--vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .button alignment given font-size hack above

  > .button {
    position: relative;
    flex: 1 1 auto;

    // Bring the hover, focused, and "active" buttons to the front to overlay
    // the borders properly
    @include hover {
      z-index: 1;
    }

    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }
}

// Optional: Group multiple button groups together for a toolbar
// .button-toolbar {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//
//   .input-group {
//     width: auto;
//   }
// }

.button-group {
  // Prevent double borders when buttons are next to each other
  > .button:not(:first-child),
  > .button-group:not(:first-child) {
    margin-left: -$btn-border-width;
  }
}

.button-group--expanded {
  display: flex;

  button,
  .button {
    justify-content: center;
  }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.button-group--sm > .button { @extend .button--sm; }

.button-group--lg > .button { @extend .button--lg; }


//
// Split button dropdowns
//

// .dropdown-toggle-split {
//   padding-right: $btn-padding-x * .75;
//   padding-left: $btn-padding-x * .75;
//
//   &::after,
//   .dropup &::after,
//   .dropright &::after {
//     margin-left: 0;
//   }
//
//   .dropleft &::before {
//     margin-right: 0;
//   }
// }
//
// .button-sm + .dropdown-toggle-split {
//   padding-right: $btn-padding-x-sm * .75;
//   padding-left: $btn-padding-x-sm * .75;
// }
//
// .button-lg + .dropdown-toggle-split {
//   padding-right: $btn-padding-x-lg * .75;
//   padding-left: $btn-padding-x-lg * .75;
// }


// The clickable button for toggling the menu
// Set the same inset shadow as the :active state
// .button-group.show .dropdown-toggle {
//   @include box-shadow($btn-active-box-shadow);
//
//   // Show no shadow for `.button-link` since it has no other button styles.
//   &.button-link {
//     @include box-shadow(none);
//   }
// }


//
// Vertical button groups
//

.button-group--vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > .button,
  > .button-group {
    width: 100%;
  }

  > .button:not(:first-child),
  > .button-group:not(:first-child) {
    margin-top: -$btn-border-width;
  }
}


// Checkbox and radio options
//
// In order to support the browser's form validation feedback, powered by the
// `required` attribute, we have to "hide" the inputs via `clip`. We cannot use
// `display: none;` or `visibility: hidden;` as that also hides the popover.
// Simply visually hiding the inputs via `opacity` would leave them clickable in
// certain cases which is prevented by using `clip` and `pointer-events`.
// This way, we ensure a DOM element is visible to position the popover from.
//
// See https://github.com/twbs/bootstrap/pull/12794 and
// https://github.com/twbs/bootstrap/pull/14559 for more information.

// .button-group-toggle {
//   > .button,
//   > .button-group > .button {
//     margin-bottom: 0; // Override default `<label>` value
//
//     input[type="radio"],
//     input[type="checkbox"] {
//       position: absolute;
//       clip: rect(0, 0, 0, 0);
//       pointer-events: none;
//     }
//   }
// }
