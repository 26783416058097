// Colors

// stylelint-disable color-no-hex

$hsd-color-base-white:    #fff !default;
$hsd-color-base-black:    #000 !default;
$hsd-color-ink:           #24242a !default;

//
// Grayscale
//


$hsd-color-gray-10:       #f9f9f9 !default;
$hsd-color-gray-20:       #dde1e6 !default;
$hsd-color-gray-30:       #c1c7cd !default;
$hsd-color-gray-40:       #a2a9b0 !default;
$hsd-color-gray-50:       #878d96 !default;
$hsd-color-gray-60:       #697077 !default;
$hsd-color-gray-70:       #4d5358 !default;
$hsd-color-gray-80:       #343a3f !default;
$hsd-color-gray-90:       #21272a !default;
$hsd-color-gray-100:      #121619 !default;

//
// Brand Colors
//

$hsd-color-red:             rgb(230, 0, 40) !default;
$hsd-color-yellow:          rgb(255, 235, 0) !default;
$hsd-color-blue:            rgb(0, 175, 215) !default;
$hsd-color-green:           rgb(100, 180, 50) !default;

//
// Text
//

$hsd-color-heading: $hsd-color-base-black !default;
$hsd-color-text: $hsd-color-ink !default;
$hsd-color-text-secondary: $hsd-color-gray-70 !default;
$hsd-color-text-inverse: $hsd-color-base-white !default;


//
// Interaction
//

$hsd-color-link: $hsd-color-ink !default;
$hsd-color-link-hover: $hsd-color-red !default;
$hsd-colo-link-activer: $hsd-color-red !default;
$hsd-color-link-visited: $hsd-color-gray-90 !default;
$hsd-color-link-focus: $hsd-color-red !default;
$hsd-color-link-hover-bg: #e6e6e6 !default;


//
// Border
//

$hsd-color-border: $hsd-color-gray-100 !default;
$hsd-color-border-light: $hsd-color-gray-30 !default;


//
// Status
//

$hsd-color-info: #005ea5 !default;	// Use for phase banners
$hsd-color-error:	#b10e1e !default;

$hsd-color-theme: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$hsd-color-theme: map-merge(
  (
    "primary":       $hsd-color-red,
    "secondary":     $hsd-color-link,
    "yellow":        $hsd-color-yellow,
    "blue":          $hsd-color-blue,
    "green":         $hsd-color-green,
  ),
  $hsd-color-theme
);

// Set a specific jump point for requesting color jumps
$hsd-color-theme-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $hsd-color-text !default;
$yiq-text-light:            $hsd-color-text-inverse !default;
