.meta {
  width: 100%;
  padding-top: spacer(sm);
  padding-bottom: spacer(sm);
  border-top: $border-width-thin solid $hsd-color-border-light;
  @include media-breakpoint-up(lg) {
    padding-top: spacer(md);
    padding-bottom: spacer(md);
  }
}
