// Spacing
//
// Control the default styling of most elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;

// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    none: 0,
    xs: ($spacer * 0.25),
    sm: ($spacer * 0.5),
    md: $spacer,
    lg: ($spacer * 1.5),
    xl: ($spacer * 2),
    xxl: ($spacer * 3.5),
    xxxl: ($spacer * 6)
  ),
  $spacers
);


$size-1: 1rem;
$size-2: 1.5rem;
$size-3: 2.5rem;
$size-4: 4.25rem;
$size-5: 6.75rem;
$size-6: 11rem;
$size-7: 18rem;
$size-8: 29rem;

$sizes: () !default;

$sizes: map-merge(
  (
    "1": $size-1,
    "2": $size-2,
    "3": $size-3,
    "4": $size-4,
    "5": $size-5,
    "6": $size-6,
    "7": $size-7,
    "8": $size-8,
  ),
  $sizes
);
